import * as React from "react";
import { IHomeFeed, IHomeFeedSearchObj, IProduct } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { getHomeFeeds, deleteAHomeFeed } from "../../services/HomeFeedService";
import { ConfirmDialogContext } from "../common/ConfirmDialogProvider";
import {
  Grid,
  Typography,
  Button,
  Fab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TableContainer,
  TextField,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  Eye,
  Pencil,
  TrashCan,
} from "mdi-material-ui";
import Loading from "../common/Loading";
import CustomerDrawer from "../common/CustomerDrawer";
import { STATUS_DICT } from "../../Constant";
import HomeFeedForm from "./HomeFeedForm";
import { getProducts } from "../../services/ProductService";
import HomeFeedProductForm from "./HomeFeedProductForm";
import NoData from "../common/NoData";

export interface HomeFeedMainProps {}

const HomeFeedMain: React.FC<HomeFeedMainProps> = () => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [homeFeeds, setHomeFeeds] = React.useState([] as IHomeFeed[]);
  const [products, setProducts] = React.useState([] as IProduct[]);
  const [productNameDict, setProductNameDict] = React.useState({} as any);
  const [hasMoreData, setHasMoreData] = React.useState(false as boolean);
  const [manageHomeFeedDialog, setManageHomeFeedDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });
  const [manageHomeFeedProductDialog, setManageHomeFeedProductDialog] =
    React.useState({
      isOpen: false,
      data: {},
      editIndex: -1,
    } as {
      isOpen: boolean;
      data: any;
      editIndex: number;
    });
  const [searchObj, setSearchObj] = React.useState({
    offset: 0,
    limit: 10,
    search: "",
    showAllData: true,
  } as IHomeFeedSearchObj);

  React.useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true);
      await getProductList();
      await getHomeFeedsList();
    };
    asyncFunc();
  }, []);

  const getHomeFeedsList = async (search?: any) => {
    setLoading(true);
    let serchObj = { ...searchObj };
    if (search != null) {
      serchObj = { ...serchObj, ...search };
    }

    const result = await getHomeFeeds(serchObj);
    if (result && result.success) {
      setHomeFeeds(result.data);
      setSearchObj(serchObj);
      setHasMoreData(result.data.length === searchObj.limit);
    } else {
      showToast(
        result?.message || "Error while getting home feeds list",
        "error"
      );
    }
    setLoading(false);
  };

  const getProductList = async () => {
    // setLoading(true);
    const result = await getProducts({ showAllData: true, isExport: true });
    if (result && result.success) {
      const productNameDict = result.data.reduce((acc: any, d) => {
        acc[d._id as string] = d.title;
        return acc;
      }, {});
      setProducts(result.data);
      setProductNameDict(productNameDict);
    } else {
      showToast(result?.message || "Error while getting product list", "error");
    }
    // setLoading(false);
  };

  const getHomeFeedsFrom = async (offset: number) => {
    let search = { ...searchObj };
    search.offset = offset;
    await getHomeFeedsList(search);
  };

  const handleDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = homeFeeds[editIndex];
    }
    setManageHomeFeedDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleDialogClose = (data?: IHomeFeed) => {
    let homeFeedsList = [...homeFeeds];
    if (data) {
      if (manageHomeFeedDialog.editIndex !== -1) {
        homeFeedsList[manageHomeFeedDialog.editIndex] = data;
      } else {
        homeFeedsList.unshift(data);
      }
    }
    setHomeFeeds(homeFeedsList);
    setManageHomeFeedDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const handleProductDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = homeFeeds[editIndex];
    }
    setManageHomeFeedProductDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleProductDialogClose = (data?: IHomeFeed) => {
    let homeFeedsList = [...homeFeeds];
    if (data) {
      if (manageHomeFeedProductDialog.editIndex !== -1) {
        homeFeedsList[manageHomeFeedProductDialog.editIndex] = data;
      }
    }
    setHomeFeeds(homeFeedsList);
    setManageHomeFeedProductDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const deleteHomeFeed = (index: number) => {
    showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
      let homeFeedsList = [...homeFeeds];
      setLoading(true);
      let result = await deleteAHomeFeed(homeFeedsList[index]._id as string);
      setLoading(false);
      if (result?.success) {
        showToast("Home Feed deleted successfully", "success");
        homeFeedsList.splice(index, 1);
        setHomeFeeds(homeFeedsList);
      } else {
        showToast(result?.message || "Error while deleting home feed", "error");
      }
    });
  };

  const handleChange = (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    setSearchObj({
      ...searchObj,
      [name]: value,
    });
  };

  const onSearch = async () => {
    let serchObj = { ...searchObj };
    serchObj.offset = 0;
    await getHomeFeedsList(serchObj);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Home Feeds</Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDialogOpen(true)}
              >
                Add Home Feed
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className="padding-16">
            <Grid
              container
              spacing={2}
              alignItems="center"
              // justify="space-around"
            >
              <Grid item lg={9} md={9} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="search"
                  label="Search by title"
                  value={searchObj.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    const searchObj = {
                      offset: 0,
                      limit: 10,
                      search: "",
                      showAllData: true,
                    };
                    await getHomeFeedsList(searchObj);
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {homeFeeds.length > 0 ? (
                  homeFeeds.map((d, index) => (
                    <TableRow key={d._id}>
                      <TableCell>
                        <Typography>{d.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{STATUS_DICT[d.status]}</Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            <Tooltip title="Update Home Feed">
                              <IconButton
                                size="medium"
                                color="primary"
                                onClick={() => handleDialogOpen(true, index)}
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Delete Home Feed">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() => deleteHomeFeed(index)}
                              >
                                <TrashCan />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="View Home Feed Products">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() =>
                                  handleProductDialogOpen(true, index)
                                }
                              >
                                <Eye />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <NoData msg="No Home Feeds found" isTable={true} />
                      {/* <br />
                      <Typography variant="subtitle1">
                        No Home Feeds found
                      </Typography>
                      <br /> */}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2} justify="flex-end" className="padding-16">
            {(searchObj?.offset || 0) > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getHomeFeedsFrom(
                      (searchObj?.offset || 0) - (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {hasMoreData && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getHomeFeedsFrom(
                      (searchObj?.offset || 0) + (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {manageHomeFeedDialog.isOpen && (
        <CustomerDrawer
          title={
            manageHomeFeedDialog?.data?._id ? "Edit Home Feed" : "Add Home Feed"
          }
          handleOpen={true}
          onClose={() => handleDialogClose()}
        >
          <HomeFeedForm
            onClose={handleDialogClose}
            data={manageHomeFeedDialog.data}
          />
        </CustomerDrawer>
      )}
      {manageHomeFeedProductDialog.isOpen && (
        <CustomerDrawer
          title={"Manage Home Feed Products"}
          handleOpen={true}
          onClose={() => handleProductDialogClose()}
        >
          <HomeFeedProductForm
            onClose={handleProductDialogClose}
            data={manageHomeFeedProductDialog.data}
            productList={products}
            productNameDict={productNameDict}
          />
        </CustomerDrawer>
      )}
    </React.Fragment>
  );
};

export default HomeFeedMain;
