import React from "react";
import CategoryMain from "../../components/category/CategoryMain";

const CategoryPage: React.FC = () => {
  return (
    <section>
      <CategoryMain />
    </section>
  );
};

export default CategoryPage;
