import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, IBanner, IBannerSearchObj } from "../vm";

export const addABanner = async (
  banner: IBanner
): Promise<IStandardAPIResponse<IBanner>> => {
  try {
    let res = await httpClient<IBanner>(`banners`, "PUT", banner);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateABanner = async (
  banner: IBanner
): Promise<IStandardAPIResponse<IBanner>> => {
  try {
    let res = await httpClient<IBanner>(
      `banners/${banner._id}`,
      "PATCH",
      banner
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const deleteABanner = async (
  bannerId: string
): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient(`banners/${bannerId}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getBanners = async (
  searchObj: IBannerSearchObj
): Promise<IStandardAPIResponse<Array<IBanner>>> => {
  try {
    let res = await httpClient<Array<IBanner>>(`banners`, "GET", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};
