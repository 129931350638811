import React from "react";
import { IUser } from "../../vm";
import {
  Grid
} from "@material-ui/core";
import { USER_ROLES, GENDERS } from "../../Constant";
import { CustomSelect, CustomSwitch, CustomTextField } from "../common/Library";

export interface UserFormPros {
  values: IUser;
  handleChange: any;
  errors: any;
  touched: any;
  onEdit: boolean;
}

const UserForm: React.FC<UserFormPros> = ({
  values,
  handleChange,
  errors,
  touched,
  onEdit,
}) => {
  return (
    <>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <CustomTextField
          label="First Name"
          name="firstName"
          disabled={onEdit === false}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <CustomTextField
          label="Middle Name"
          name="middleName"
          disabled={onEdit === false}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <CustomTextField
          label="Last Name"
          name="lastName"
          disabled={onEdit === false}
        />
      </Grid>
      <Grid
        item
        lg={values._id ? 6 : 12}
        md={values._id ? 6 : 12}
        sm={12}
        xs={12}
      >
        <CustomTextField
          label="Email"
          name="email"
          disabled={onEdit === false}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomTextField
          label="Phone"
          name="phone"
          disabled={onEdit === false}
        />
      </Grid>
      {!values._id && (
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomTextField
            type="password"
            label="Password"
            name="password"
            disabled={onEdit === false}
          />
        </Grid>
      )}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomSelect
          name="roleId"
          label="Role"
          menuList={Object.keys(USER_ROLES).map((d) => {
            return { value: d, label: USER_ROLES[d] };
          })}
          disabled={onEdit === false || values._id ? true : false}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomSelect
          name="gender"
          label="Gender"
          menuList={Object.keys(GENDERS).map((d) => {
            return { value: d, label: GENDERS[d] };
          })}
          disabled={onEdit === false}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomSwitch
          name="isEnabled"
          color="secondary"
          disabled={onEdit === false}
          label={values.isEnabled ? "Enabled" : "Disabled"}
        />
      </Grid>
    </>
  );
};

export default UserForm;
