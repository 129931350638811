import * as React from "react";
import { ILogin, ClassProps } from "../../vm";
import {
  isTokenExpired,
  setToken,
  getQueryParams,
} from "../../services/UtilServices";
import { ToastContext } from "../common/ToastProvider";
//import { Lock } from "mdi-material-ui";
import { Formik, FormikErrors } from "formik";
//import { EMAIL_REGEX } from "../../Constant";
import { loginToApp } from "../../services/AuthService";
import { CustomTextField } from "../common/Library";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  withStyles,
  Paper,
  Theme,
  createStyles
} from "@material-ui/core";

import * as yup from "yup";

const loginSchema = yup.object().shape({
  email: yup.string().email().required("required"),
  password: yup.string().min(4).required("required"),
});

export interface LoginProps extends ClassProps { }

const Login: React.FC<LoginProps> = ({ classes }) => {

  const history = useHistory();
  const { showToast } = React.useContext(ToastContext);
  React.useLayoutEffect(() => {
    let hasTokenExpired = isTokenExpired();
    if (!hasTokenExpired) {
      history.push("/dashboard");
    }
  }, []);


  return (
    <React.Fragment>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <img src="/static/images/icons/logo512.png" height="250px" width="100px" alt="" className={classes.logo} />

          <Typography variant="h5">Sign In</Typography>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={async (values: ILogin, { setSubmitting }) => {
              let res = await loginToApp(values);
              if (res?.success && res.data) {
                setToken(res.data.token);
                // let tokenData = parseJwt(res.data.token);
                let params: any = getQueryParams(window.location.href);
                if (params["redirect_to"]) {
                  history.push(params["redirect_to"]);
                } else {
                  history.push(`/dashboard`);
                }
              } else {
                showToast(res?.message || "Error while log in", "error");
              }
              // history.push(`/dashboard`);
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <CustomTextField name="email" label="Email" />
                <CustomTextField
                  name="password"
                  type="password"
                  label="Password"
                />

                <br />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Sign in
                </Button>
              </form>
            )}
          </Formik>
        </Paper>
      </main>
    </React.Fragment>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      marginTop: "8rem",
      maxWidth: "700px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing() * 3
        }px`,
    },
    logo: {
      height: "86px",
    },
    avatar: {
      margin: theme.spacing(),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      //   width: "100%",
      marginTop: theme.spacing(),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  });

export default withStyles(styles)(Login);