import React from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TableContainer,
  Chip,
  TextField,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  ICategory,
  ILocationGroup,
  IProduct,
  IProductSearchObj,
  ISelectMenuList,
  ISubCategory,
} from "../../vm";
import { ConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
  ChevronLeft,
  ChevronRight,
  Pencil,
  TrashCan,
  ViewList,
} from "mdi-material-ui";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { getProducts, deleteAProduct } from "../../services/ProductService";
import NoData from "../common/NoData";
import CustomerDrawer from "../common/CustomerDrawer";
import ProductForm from "./ProductForm";
import { getSubCategories } from "../../services/SubCategoryService";
import { ReactSelect } from "../common/Library";
import {
  DEFAULT_IMG_URL,
  PRODUCT_UNIT,
  STATUS_DICT,
  STATUS_DICT_FOR_PRODUCT,
  STATUS_DICT_FOR_PRODUCT_WITH_ALL,
} from "../../Constant";
import { getCategories } from "../../services/CategoryService";
import { getLocationGroups } from "../../services/LocationGroupService";
import { ProductOptionMain } from "./options/ProductOptionMain";

export interface ProductMainProps { }

const ProductMain: React.FC<ProductMainProps> = () => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
  const queryParams = new URLSearchParams(window.location.search);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [products, setProducts] = React.useState([] as IProduct[]);
  const [manageProductDialog, setManageProductDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });
  const [selectedProductIndex, setSelectedProductIndex] = React.useState<
    number | null
  >(null);
  const [categories, setCategories] = React.useState([] as ICategory[]);
  const [categoryOptions, setCategoryOptions] = React.useState(
    [] as ISelectMenuList[]
  );
  const [categoryDict, setCategoryDict] = React.useState({} as any);
  const [subCategories, setSubCategories] = React.useState(
    [] as ISubCategory[]
  );
  const [subCategoryOptions, setSubCategoryOptions] = React.useState(
    [] as ISelectMenuList[]
  );
  const [subCategoryDict, setSubCategoryDict] = React.useState({} as any);
  const [searchObj, setSearchObj] = React.useState({
    offset: 0,
    limit: 10,
    search: "",
    subCategoryIds: [],
    categoryIds: [],
    status: queryParams.get("status") ? Number(queryParams.get("status")) : 9,
    showAllData: true,
    productId: queryParams.get("_id") ? queryParams.get("_id") : ""
  } as IProductSearchObj);
  const [hasMoreProducts, setHasMoreProducts] = React.useState(
    false as boolean
  );
  const [locationGroupNameDict, setLocationGroupNameDict] = React.useState(
    {} as any
  );
  const [locationGroupNames, setLocationGroupName] = React.useState(
    [] as ILocationGroup[]
  );

  React.useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true);
      await getAllCategories();
      await getAllSubCategories();
      await getAllLocationgroup();
      await getAllProduct();
    };
    asyncFunc();
  }, []);

  const getAllProduct = async (search?: any) => {
    setLoading(true);
    let serchObj = { ...searchObj };
    if (search != null) {
      serchObj = { ...serchObj, ...search };
    }
    if (Number(serchObj.status) === 9) {
      delete serchObj.status;
      serchObj.showAllData = true;
    } else {
      serchObj.status = Number(serchObj.status);
      serchObj.showAllData = false;
    }
    const result = await getProducts(serchObj);
    // replace url to original url
    if (queryParams.get("status") || queryParams.get("_id")) {
      window.history.replaceState(null, "", "/products");
      serchObj.productId = "";
    }
    if (serchObj.status == null) {
      serchObj.status = 9;
    }
    if (result && result.success) {
      setProducts(result.data);
      setSearchObj(serchObj);
      setHasMoreProducts(result.data.length === serchObj.limit);
    } else {
      showToast(result?.message || "Error while getting products", "error");
    }
    setLoading(false);
  };

  const getAllCategories = async () => {
    // setLoading(true);
    const result = await getCategories({ showAllData: true, isExport: true });
    if (result && result.success) {
      const categoryDict: any = {};
      const category: ISelectMenuList[] = result.data.map((d) => {
        categoryDict[d._id as string] = d.title;
        return { value: d._id as string, label: d.title };
      });
      setCategoryOptions(category);
      setCategories(result.data);
      setCategoryDict(categoryDict);
    } else {
      showToast(result?.message || "Error while getting categories", "error");
    }
    // setLoading(false);
  };

  const getAllSubCategories = async () => {
    // setLoading(true);
    const result = await getSubCategories({
      showAllData: true,
      isExport: true,
    });
    if (result && result.success) {
      const subCategoryDict: any = {};
      const subCategory: ISelectMenuList[] = result.data.map((d) => {
        subCategoryDict[d._id as string] = d.title;
        return { value: d._id as string, label: d.title };
      });
      setSubCategoryOptions(subCategory);
      setSubCategoryDict(subCategoryDict);
      setSubCategories(result.data);
    } else {
      showToast(
        result?.message || "Error while getting sub-categories",
        "error"
      );
    }
    // setLoading(false);
  };

  const getAllLocationgroup = async () => {
    // setLoading(true);
    const result = await getLocationGroups({
      showAllData: true,
      isExport: true,
    });
    if (result && result.success) {
      const locationGroupNameDict: any = {};
      result.data.forEach((d) => {
        locationGroupNameDict[d._id as string] = d.title;
      });
      setLocationGroupNameDict(locationGroupNameDict);
      setLocationGroupName(result.data);
    } else {
      showToast(
        result?.message || "Error while getting location-group",
        "error"
      );
    }
    // setLoading(false);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = products[editIndex];
    }
    setManageProductDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };
  const handleViewProductOption = (index: number) => {
    setSelectedProductIndex(index);
  };
  const handleViewProductOptionClose = () => {
    setSelectedProductIndex(null);
  };

  const handleClose = (data?: IProduct) => {
    let productList = [...products];
    if (data) {
      if (manageProductDialog.editIndex !== -1) {
        productList[manageProductDialog.editIndex] = data;
      } else {
        productList.unshift(data);
      }
    }
    setProducts(productList);
    setManageProductDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const deleteProduct = (index: number) => {
    showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
      let productList = [...products];
      setLoading(true);
      let result = await deleteAProduct(productList[index]._id as string);
      setLoading(false);
      if (result?.success) {
        showToast("products deleted successfully", "success");
        productList.splice(index, 1);
        setProducts(productList);
      } else {
        showToast(result?.message || "Error while deleting products", "error");
      }
    });
  };

  const getProductsFrom = async (offset: number) => {
    let search = { ...searchObj };
    search.offset = offset;
    await getAllProduct(search);
  };

  const handleChange = (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    setSearchObj({
      ...searchObj,
      [name]: value,
    });
  };

  const onSearch = async () => {
    let serchObj = { ...searchObj };
    serchObj.offset = 0;
    await getAllProduct(serchObj);
  };

  const onCategoryChange = (categoryIds: any) => {
    const ids: string[] = [];
    const subCategoryOptions = subCategories
      .filter((d) => categoryIds.includes(d.categoryId))
      .map((d) => {
        ids.push(d._id as string);
        return { value: d._id as string, label: d.title };
      });
    let subCategoryIds: string[] = [];
    if (searchObj.subCategoryIds && searchObj.subCategoryIds.length > 0) {
      subCategoryIds = [...searchObj.subCategoryIds].filter((d) =>
        ids.includes(d)
      );
    }
    setSearchObj({ ...searchObj, categoryIds, subCategoryIds });
    setSubCategoryOptions(subCategoryOptions);
  };

  const onSubCategoryChange = (subCategoryIds: any) => {
    setSearchObj({ ...searchObj, subCategoryIds });
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  return (
    <React.Fragment>
      {isLoading &&
        <div style={{ justifyContent: "center", textAlign: "center", display: "flex", paddingTop: "5rem" }}>


          <Loading />
        </div>
      }
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Products</Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddDialogOpen(true)}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className="padding-16">
            <Grid
              container
              spacing={2}
              alignItems="center"
            // justify="space-around"
            >
              {/* <Grid item lg={3} md={4} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  value={state.searchObj.name}
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="search"
                  label="Search by name/slug/price unit/unit labels"
                  value={searchObj.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ReactSelect
                  value={searchObj.categoryIds}
                  isClearable={true}
                  isMulti={true}
                  options={categoryOptions}
                  onChange={onCategoryChange}
                  label={"Categories"}
                />
              </Grid>
              <Grid item lg={5} md={5} sm={6} xs={12}>
                <ReactSelect
                  value={searchObj.subCategoryIds}
                  isClearable={true}
                  isMulti={true}
                  options={
                    searchObj.categoryIds && searchObj.categoryIds.length > 0
                      ? subCategoryOptions
                      : []
                  }
                  onChange={onSubCategoryChange}
                  label={"Sub-Categories"}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="filterByStatus" className="select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="filterByStatus"
                    value={searchObj.status}
                    name="status"
                    onChange={handleChange}
                  >
                    {Object.keys(STATUS_DICT_FOR_PRODUCT_WITH_ALL).map(
                      (index) => (
                        <MenuItem value={index} key={index}>
                          {STATUS_DICT_FOR_PRODUCT_WITH_ALL[index]}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="filterByEnable" className="select-label">
                    Enable Type
                  </InputLabel>
                  <Select
                    labelId="filterByEnable"
                    value={state.searchObj.isEnabled}
                    name="showEnabled"
                    onChange={handleChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="enabled">Enabled</MenuItem>
                    <MenuItem value="disabled">Disabled</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item>
                <Button variant="contained" color="primary" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    const searchObj = {
                      offset: 0,
                      limit: 10,
                      // name: "",
                      // roleId: 99,
                      // email: "",
                      search: "",
                      // userId: "",
                      // isEnabled: "all",
                      subCategoryIds: [],
                      categoryIds: [],
                      status: 9,
                      showAllData: true,
                    };
                    await getAllProduct(searchObj);
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead >
                <TableRow >
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Categories</TableCell>
                  <TableCell>Sub-Categories</TableCell>
                  <TableCell>Locations Group Name</TableCell>
                  <TableCell>Delivery Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.length > 0 ? (
                  products.map((product, productIndex) => (
                    <TableRow key={product._id}>
                      <TableCell>
                        {product.imageUrl ? (
                          <img
                            src={product.imageUrl}
                            alt={product.title}
                            className="sub-category-img"
                          />
                        ) : (
                          <img
                            src={DEFAULT_IMG_URL}
                            alt={product.title}
                            className="sub-category-img"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography>{product.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container xs={12} spacing={1}>
                          {product.categoryIds.map((d) => (
                            <Grid item>
                              <Chip
                                color="primary"
                                label={categoryDict[d] || "NA"}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container xs={12} spacing={1}>
                          {product.subCategoryIds.map((d) => (
                            <Grid item>
                              <Chip
                                color="secondary"
                                label={subCategoryDict[d] || "NA"}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </TableCell>

                      <TableCell>
                        <Grid container xs={12} spacing={1}>
                          {product.locationGroupIds.map((d) => (
                            <Grid item>
                              <Chip label={locationGroupNameDict[d] || "NA"} />
                            </Grid>
                          ))}
                        </Grid>
                      </TableCell>

                      <TableCell>
                        <Typography>
                          <Typography>
                            {product.deliveryTime
                              ? product.deliveryTime + " mins"
                              : "NA"}
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <Typography>
                            {STATUS_DICT_FOR_PRODUCT[product.status]}
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            <Tooltip title="Update Product">
                              <IconButton
                                size="medium"
                                color="primary"
                                onClick={() =>
                                  handleAddDialogOpen(true, productIndex)
                                }
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="View Product options">
                              <IconButton
                                size="medium"
                                color="default"
                                onClick={() =>
                                  handleViewProductOption(productIndex)
                                }
                              >
                                <ViewList />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Delete User">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() => deleteProduct(productIndex)}
                              >
                                <TrashCan />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <NoData msg="No Product found" isTable={true} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2} justify="flex-end" className="padding-16">
            {(searchObj?.offset || 0) > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getProductsFrom(
                      (searchObj?.offset || 0) - (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {hasMoreProducts && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getProductsFrom(
                      (searchObj?.offset || 0) + (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {manageProductDialog.isOpen && (
        <CustomerDrawer
          title={
            manageProductDialog?.data?._id ? "Edit Product" : "Add Product"
          }
          handleOpen={true}
          onClose={() => handleClose()}
        >
          <ProductForm
            onClose={handleClose}
            data={manageProductDialog.data}
            categoryOptions={categoryOptions}
            subCategories={subCategories}
            locationGroupNames={locationGroupNames}
          />
        </CustomerDrawer>
      )}
      {selectedProductIndex != null && (
        <CustomerDrawer
          title={"Product Options"}
          handleOpen={true}
          onClose={() => handleViewProductOptionClose()}
        >
          <br />
          <ProductOptionMain
            productId={products[selectedProductIndex]?._id as string}
            product={products[selectedProductIndex]}
          />
        </CustomerDrawer>
      )}
    </React.Fragment>
  );
};

export default ProductMain;
