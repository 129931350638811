
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

export const THEME = createMuiTheme({
    palette: {
        primary: {
            main: "#fc801b",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#4caf4f",
            contrastText: "#ffffff",
        },
    },
});