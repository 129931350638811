import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import UserProfileMain from "../../components/users/user-profile/UserProfileMain";
import UserAddressDetails from "../../components/users/user-address/UserAddressDetails";
import UserOrderHistory from "../../components/users/user-order-history/UserOrderHistory";
import { useParams } from "react-router-dom";

export interface UserProfilePageProps {}

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    margin: -16,
  },
  mainSection: {
    padding: 16,
  },
}));

const UserProfilePage: React.FC<UserProfilePageProps> = () => {
  // custom hooks
  const params: any = useParams();

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <main className={classes.mainContainer}>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Profile" />
          <Tab label="Address Details" />
          <Tab label="Order History" />
        </Tabs>
      </AppBar>
      <section className={classes.mainSection}>
        {value === 0 && <UserProfileMain userId={params.userId} />}
        {value === 1 && <UserAddressDetails userId={params.userId} />}
        {value === 2 && <UserOrderHistory userId={params.userId} />}
      </section>
    </main>
  );
};

export default UserProfilePage;
