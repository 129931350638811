import React from "react";
import {
    Grid,
    Typography,
    Button,
    Paper,
    Tooltip,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Fab,
} from "@material-ui/core";
import { IShop, IBannerSearchObj } from "../../vm";
import { ConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
    Pencil,
    TrashCan,
    ChevronLeft,
    ChevronRight,
} from "mdi-material-ui";
import { deleteABanner } from "../../services/BannerService";
import ShopForm from "./ShopForm";
import NoData from "../common/NoData";
import CustomerDrawer from "../common/CustomerDrawer";
import { DEFAULT_IMG_URL } from "../../Constant";
import { getShops } from "../../services/ShopService";

export interface ShopMainProps { }

const ShopMain: React.FC<ShopMainProps> = () => {
    // custom hooks
    const { showToast } = React.useContext(ToastContext);
    const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

    // state
    const [isLoading, setLoading] = React.useState(false as boolean);
    const [shops, setShops] = React.useState([] as IShop[]);
    const [hasMoreBanners, setHasMoreBanners] = React.useState(false as boolean);
    const [manageBannerDialog, setManageBannerDialog] = React.useState({
        isOpen: false,
        data: {},
        editIndex: -1,
    } as {
        isOpen: boolean;
        data: any;
        editIndex: number;
    });
    const [searchObj, setSearchObj] = React.useState({
        offset: 0,
        limit: 10,
        search: "",
        status: 9,
        showAllData: true,
    } as IBannerSearchObj);

    React.useEffect(() => {
        const asyncFunc = async () => {
            await getAllBanner();
        };
        asyncFunc();
    }, []);

    const getAllBanner = async (search?: any) => {
        setLoading(true);
        let serchObj = { ...searchObj };
        if (search != null) {
            serchObj = { ...serchObj, ...search };
        }
        const status = serchObj.status;
        if (serchObj.status == 9) {
            delete serchObj.status;
        }
        const result = await getShops(serchObj);
        if (status == 9) {
            serchObj.status = 9;
        }
        if (result && result.success) {
            //@ts-ignore
            setShops(result.data);
            setSearchObj(serchObj);
            setHasMoreBanners(result.data.length === serchObj.limit);
        } else {
            showToast(result?.message || "Error while getting banners", "error");
        }
        setLoading(false);
    };

    const getBannersFrom = async (offset: number) => {
        let search = { ...searchObj };
        search.offset = offset;
        await getAllBanner(search);
    };

    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = shops[editIndex];
        }
        setManageBannerDialog({
            isOpen: isOpen,
            data: data,
            editIndex: editIndex !== undefined ? editIndex : -1,
        });
    };

    const handleClose = (data?: IShop) => {
        let shopList = [...shops];
        if (data) {
            if (manageBannerDialog.editIndex !== -1) {
                shopList[manageBannerDialog.editIndex] = data;
            } else {
                shopList.unshift(data);
            }
        }
        setShops(shopList);
        setManageBannerDialog({
            isOpen: false,
            data: undefined,
            editIndex: -1,
        });
    };

    const deleteCategory = (index: number) => {
        showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
            let shopList = [...shops];
            setLoading(true);
            let result = await deleteABanner(shopList[index]._id as string);
            setLoading(false);
            if (result?.success) {
                showToast("Banner deleted successfully", "success");
                shopList.splice(index, 1);
                setShops(shopList);
            } else {
                showToast(result?.message || "Error while deleting banner", "error");
            }
        });
    };

    //   const handleChange = (event: any) => {
    //     let name = event.target.name,
    //       value = event.target.value;
    //     setSearchObj({
    //       ...searchObj,
    //       [name]: value,
    //     });
    //   };

    //   const onSearch = async () => {
    //     let serchObj = { ...searchObj };
    //     serchObj.offset = 0;
    //     await getAllBanners(serchObj);
    //   };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item>
                            <Typography variant="h5">Restaurants/Shops</Typography>
                        </Grid>

                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddDialogOpen(true)}
                            >
                                Add Shop
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12}>
          <Paper className="padding-16">
            <Grid container spacing={2} alignItems="center">
              <Grid item lg={9} md={9} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="search"
                  label="Search by name / slug"
                  value={searchObj.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    const searchObj = {
                      offset: 0,
                      limit: 10,
                      search: "",
                    };
                    await getAllCategory(searchObj);
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Shop Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Owner Name</TableCell>
                                    <TableCell>Contact/Mobile</TableCell>
                                    {/* <TableCell>Is Enabled</TableCell> */}
                                    <TableCell>Address</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shops.length > 0 ? (
                                    shops.map((d, index) => (
                                        <TableRow key={d._id}>
                                            <TableCell>
                                                {d.photoUrl ? (
                                                    <img
                                                        src={d.photoUrl}
                                                        alt="log"
                                                        className="sub-category-img"
                                                    />
                                                ) : (
                                                    <img
                                                        src={DEFAULT_IMG_URL}
                                                        alt="log"
                                                        className="sub-category-img"
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{d.shopId}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{d.name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{d.ownerName}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{d.ownerContact}/{d.ownerMobile}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{d.address}</Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <Tooltip title="Update Banner">
                                                            <IconButton
                                                                size="medium"
                                                                color="primary"
                                                                onClick={() => handleAddDialogOpen(true, index)}
                                                            >
                                                                <Pencil />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Delete Banner">
                                                            <IconButton
                                                                size="medium"
                                                                color="secondary"
                                                                onClick={() => deleteCategory(index)}
                                                            >
                                                                <TrashCan />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <NoData msg="No Banner found" isTable={true} />
                                            {/* <br />
                      <Typography variant="subtitle1">
                        No Banner found
                      </Typography>
                      <br /> */}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2} justify="flex-end" className="padding-16">
                        {(searchObj?.offset || 0) > 0 && (
                            <Grid item>
                                <Fab
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        getBannersFrom(
                                            (searchObj?.offset || 0) - (searchObj?.limit || 0)
                                        )
                                    }
                                >
                                    <ChevronLeft />
                                </Fab>
                            </Grid>
                        )}
                        {hasMoreBanners && (
                            <Grid item>
                                <Fab
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                        getBannersFrom(
                                            (searchObj?.offset || 0) + (searchObj?.limit || 0)
                                        )
                                    }
                                >
                                    <ChevronRight />
                                </Fab>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {manageBannerDialog.isOpen && (
                <CustomerDrawer
                    title={manageBannerDialog?.data?._id ? "Edit Shop" : "Add Shop"}
                    handleOpen={true}
                    onClose={() => handleClose()}
                >
                    <ShopForm onClose={handleClose} data={manageBannerDialog.data} />
                </CustomerDrawer>
            )}
        </React.Fragment>
    );
};

export default ShopMain;
