import React from "react";
import UserMain from "../../components/users/UserMain";

const UsersPage: React.FC = () => {
  return (
    <section>
      <UserMain />
    </section>
  );
};

export default UsersPage;
