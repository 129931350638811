import { Card, CardContent, Typography, Grid, styled } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";


export interface DashboardCardProps {
  icon?: any;
  title: string;
  count: number;
  color?: string;
  secondaryColor?: string;
  url: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  icon,
  title,
  count,
  color,
  secondaryColor,
  url,
}) => {
  // custom hooks
  const history = useHistory();

  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(7),
    height: theme.spacing(7),
    justifyContent: "center",
    color: color,
    backgroundColor: secondaryColor,
  }));

  const CardStyle = styled("div")(({ theme }) => ({
    color: color,
    backgroundColor: secondaryColor,
  }));

  const onClickOnCard = (url: string) => {
    history.push(url);
  };

  return (
    <Card

      onClick={() => onClickOnCard(url)} className="pointer">
      <CardStyle>


        <CardContent>
          <Grid container spacing={0} alignItems="center" justify="center">
            {icon && (
              <Grid item xs={4}>
                <IconWrapperStyle>{icon}</IconWrapperStyle>
              </Grid>
            )}
            <Grid
              item
              xs={!icon ? 12 : 8}
              className={!icon ? "text-align-center" : ""}
            >
              <Typography variant="h3" style={{ color: `${color}` }}>{count}</Typography>

              <Typography variant="subtitle2" >
                {title}
              </Typography>

            </Grid>
          </Grid>
        </CardContent>
      </CardStyle>
    </Card>
  );
};

export default DashboardCard;
