import React from "react";
import { getOrdersDashboardData } from "../../services/DashboardService";
import { IDashboardPaymentData, IOrderDashboardSearch } from "../../vm";
import moment from "moment";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Divider,
} from "@material-ui/core";
import { ResponsivePie } from "@nivo/pie";
import {
  ORDER_STATUS,
  PAYMENT_METHOD,
  TIME_PERIOD,
  USER_ROLES,
} from "../../Constant";
import DashboardCard from "./DashboardCard";
import NoData from "../common/NoData";
import { useHistory } from "react-router";

const COLOR_DICT: any = {
  //   "0": "Order Initiated",
  "1": "#6320ab",
  "-1": "#d62515",
  "2": "#20ab27",
  "-2": "#ff7f50",
  "-3": "#42f5c5",
  "-4": "#f58442",
  "5": "#056608",
  "-5": "#daa520",
  //   "6": "Order in progress",
  "7": "#2096ab",
};

const PAYMENT_AMOUNT_DICT: any = {
  ...PAYMENT_METHOD,
  totalAmount: "Total Payment",
  totalDeliveryCharge: "Total Delivery Charge",
};

var totalOrderCount: number;

export interface IOrdersDashboardProps { }

const OrdersDashboard: React.FC<IOrdersDashboardProps> = ({ }) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const history: any = useHistory();

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [orderData, setOrderData] = React.useState(
    [] as Array<{ _id: number; count: number }>
  );
  const [pieChartData, setPieChartData] = React.useState([] as any[]);
  const [paymentDetailsData, setPaymentDetailsData] = React.useState(
    [] as Array<{ _id: string; count: number }>
  );
  const [userData, setUserData] = React.useState(
    [] as Array<{ _id: number; count: number }>
  );
  const [searchObj, setSearchObj] = React.useState({
    timePeriod: "all",
    startDate: undefined,
    endDate: undefined,
  } as IOrderDashboardSearch);

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getOrdersDashboardDataDetails();
    };
    asyncFunc();
  }, []);

  const getOrdersDashboardDataDetails = async (search?: any) => {
    let serchObj = { ...searchObj };
    if (search) {
      serchObj = { ...serchObj, ...search };
    }
    setLoading(true);
    // manage date period
    switch (serchObj.timePeriod) {
      case "today":
        serchObj.startDate = moment().format("MM/DD/YYYY");
        serchObj.endDate = moment().format("MM/DD/YYYY");
        break;
      case "this-week":
        serchObj.startDate = moment().startOf("week").format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("week").format("MM/DD/YYYY");
        break;
      case "this-month":
        serchObj.startDate = moment().startOf("month").format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "last-three-month":
        serchObj.startDate = moment()
          .subtract(2, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "last-nine-month":
        serchObj.startDate = moment()
          .subtract(8, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "custom-date":
        serchObj.startDate = moment(serchObj.startDate).format("MM/DD/YYYY");
        serchObj.endDate = moment(serchObj.endDate).format("MM/DD/YYYY");
        break;
      default:
        delete serchObj.startDate;
        delete serchObj.endDate;
        break;
    }
    const result = await getOrdersDashboardData(serchObj);
    if (result?.success) {
      // checking order status
      const orderStatus: any = [];
      const pieChartData: any = [];
      totalOrderCount = 0;
      [1, -1, 2, -2, -3, -4, 5, -5, 7].forEach((d) => {
        let order = result.data.orderStatus.find((ele) => ele._id === d);
        if (order) {
          pieChartData.push({
            // id: order._id,
            id: ORDER_STATUS[order._id],
            // label: ORDER_STATUS[order._id],
            label: order._id,
            value: order.count,
            color: COLOR_DICT[order._id],
          });
          // showing only this 4 status in card
          if ([1, 2, 5].includes(d)) {
            orderStatus.push(order);
          }
          // calculate total orders count
          totalOrderCount += order.count;
        } else {
          //   pieChartData.push({
          //     id: d,
          //     label: ORDER_STATUS[d],
          //     value: 0,
          //     color: COLOR_DICT[d],
          //   });
          // showing only this 4 status in card
          if ([1, 2, 5].includes(d)) {
            orderStatus.push({ _id: d, count: 0 });
          }
        }
      });
      // checking user role
      const userData: any = [];
      [1, 2, 0].forEach((d) => {
        let user = result.data.userCountData.find((ele) => ele._id === d);
        if (user) {
          userData.push(user);
        } else {
          userData.push({ _id: d, count: 0 });
        }
      });
      // checking payment type details
      const paymentData: any = [];
      let totalAmount = 0;
      let totalDeliveryCharge = 0;
      ["cash", "upi"].forEach((d) => {
        let payment = result.data.paymentDetails.find((ele) => ele._id === d);
        if (payment) {
          paymentData.push({ _id: payment._id, count: payment.totalPrice });
          totalAmount += payment.totalPrice;
          totalDeliveryCharge += payment.totalDeliveryCharge;
        } else {
          paymentData.push({ _id: d, count: 0 });
        }
      });
      paymentData.push({ _id: "totalAmount", count: totalAmount });
      paymentData.push({
        _id: "totalDeliveryCharge",
        count: totalDeliveryCharge,
      });

      setOrderData(orderStatus);
      setPieChartData(pieChartData);
      setUserData(userData);
      setPaymentDetailsData(paymentData);
    } else {
      showToast(result?.message || "Error while getting orders data", "error");
    }
    setSearchObj(serchObj);
    setLoading(false);
  };

  const handleChange = async (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    let serchObj;
    if (name === "timePeriod" && value === "custom-date") {
      serchObj = {
        ...searchObj,
        timePeriod: value,
        startDate: undefined,
        endDate: undefined,
      };
    } else {
      serchObj = {
        ...searchObj,
        [name]: value,
      };
    }
    await getOrdersDashboardDataDetails(serchObj);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={0}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justify="space-between"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" className="dashboard-title">
                        Orders
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={9} sm={10} xs={10}>
                      <Grid container spacing={1} justify="flex-end">
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel
                              id="timePeriod"
                              className="select-label"
                            >
                              Time Period
                            </InputLabel>
                            <Select
                              labelId="timePeriod"
                              value={searchObj.timePeriod}
                              name="timePeriod"
                              onChange={handleChange}
                            >
                              {Object.keys(TIME_PERIOD).map((index) => (
                                <MenuItem value={index} key={index}>
                                  {TIME_PERIOD[index]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {searchObj.timePeriod === "custom-date" && (
                          <>
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                              <TextField
                                fullWidth
                                name="startDate"
                                type="date"
                                label="Start Date"
                                value={moment(searchObj.startDate).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={6}>
                              <TextField
                                fullWidth
                                name="endDate"
                                type="date"
                                label="End Date"
                                value={moment(searchObj.endDate).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={handleChange}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className="mt-10" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Grid container spacing={3}>
                        {orderData.map((d, index) => {
                          return (
                            <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
                              <DashboardCard
                                title={ORDER_STATUS[d._id]}
                                count={d.count}
                                // icon={<AccountGroup />}
                                color="white"
                                secondaryColor="#35baf6"
                                url={
                                  searchObj.timePeriod === "custom-date"
                                    ? `/orders?status=${d._id}&timePeriod=${searchObj.timePeriod}&startDate=${searchObj.startDate}&endDate=${searchObj.endDate}`
                                    : `/orders?status=${d._id}&timePeriod=${searchObj.timePeriod}`
                                }
                              />
                            </Grid>
                          );
                        })}
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          key={`total_order`}
                        >
                          <DashboardCard
                            title={"Total Orders"}
                            count={totalOrderCount}
                            // icon={<AccountGroup />}
                            color="white"
                            secondaryColor="#35baf6"
                            url={
                              searchObj.timePeriod === "custom-date"
                                ? `/orders?timePeriod=${searchObj.timePeriod}&startDate=${searchObj.startDate}&endDate=${searchObj.endDate}`
                                : `/orders?timePeriod=${searchObj.timePeriod}`
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item lg={3} md={2} sm={6} xs={12}></Grid> */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      {pieChartData && pieChartData.length > 0 ? (
                        <div className="pie-container">
                          <ResponsivePie
                            data={pieChartData}
                            margin={{
                              top: 30,
                              right: 20,
                              bottom: 20,
                              left: 20,
                            }}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            onClick={(data) => {
                              if (searchObj.timePeriod === "custom-date") {
                                history.push(
                                  `/orders?status=${data.data.label}&timePeriod=${searchObj.timePeriod}&startDate=${searchObj.startDate}&endDate=${searchObj.endDate}`
                                );
                              } else {
                                history.push(
                                  `/orders?status=${data.data.label}&timePeriod=${searchObj.timePeriod}`
                                );
                              }
                              //   console.log("DATA", data)
                            }}
                            // colors={({ id }) => {
                            //   switch (id) {
                            //     case "1":
                            //       return "#6320ab";
                            //     case "-1":
                            //       return "#d62515";
                            //     case "2":
                            //       return "#20ab27";
                            //     case "-2":
                            //       return "#ff7f50";
                            //     case "-3":
                            //       return "#42f5c5";
                            //     case "-4":
                            //       return "#f58442";
                            //     case "5":
                            //       return "#056608";
                            //     case "-5":
                            //       return "#daa520";
                            //     case "7":
                            //       return "#2096ab";
                            //   }
                            //   return "#ddd";
                            // }}
                            sortByValue={true}
                            borderWidth={1}
                            borderColor={{
                              from: "color",
                              modifiers: [["darker", 0.2]],
                            }}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: "color" }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                              from: "color",
                              modifiers: [["darker", 2]],
                            }}
                            animate={true}
                          // legends={[
                          //   {
                          //     anchor: "bottom",
                          //     direction: "row",
                          //     justify: false,
                          //     translateX: 0,
                          //     translateY: 0,
                          //     itemsSpacing: 0,
                          //     itemWidth: 100,
                          //     itemHeight: 18,
                          //     itemTextColor: "#999",
                          //     itemDirection: "left-to-right",
                          //     itemOpacity: 1,
                          //     symbolSize: 18,
                          //     symbolShape: "circle",
                          //     effects: [
                          //       {
                          //         on: "hover",
                          //         style: {
                          //           itemTextColor: "#000",
                          //         },
                          //       },
                          //     ],
                          //   },
                          // ]}
                          />
                        </div>
                      ) : (
                        <NoData msg="No Order Found" />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h6" className="dashboard-title">
                    Payment Details
                  </Typography>
                  <Divider className="mt-10 mb-3" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={3} alignItems="center">
                    {paymentDetailsData.map((d, index) => {
                      return (
                        <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
                          <DashboardCard
                            title={PAYMENT_AMOUNT_DICT[d._id]}
                            count={d.count}
                            // icon={<AccountGroup />}
                            color="white"
                            secondaryColor="#ff9800"
                            url={
                              searchObj.timePeriod === "custom-date"
                                ? `/orders?paymentMethod=${["cash", "online"].includes(d._id)
                                  ? d._id
                                  : "all"
                                }&timePeriod=${searchObj.timePeriod
                                }&startDate=${searchObj.startDate}&endDate=${searchObj.endDate
                                }`
                                : `/orders?paymentMethod=${["cash", "online"].includes(d._id)
                                  ? d._id
                                  : "all"
                                }&timePeriod=${searchObj.timePeriod}`
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h6" className="dashboard-title">
                    Users
                  </Typography>
                  <Divider className="mt-10 mb-3" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={3} alignItems="center">
                    {userData.map((d, index) => {
                      return (
                        <Grid
                          item
                          lg={4}
                          md={4}
                          sm={4}
                          xs={index === userData.length - 1 ? 12 : 6}
                          key={index}
                        >
                          <DashboardCard
                            title={USER_ROLES[d._id]}
                            count={d.count}
                            // icon={<AccountGroup />}
                            color="white"
                            secondaryColor="#009688"
                            url={
                              searchObj.timePeriod === "custom-date"
                                ? `/users?roleId=${d._id}&timePeriod=${searchObj.timePeriod}&startDate=${searchObj.startDate}&endDate=${searchObj.endDate}`
                                : `/users?roleId=${d._id}&timePeriod=${searchObj.timePeriod}`
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default OrdersDashboard;
