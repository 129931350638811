import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { ClassProps } from "./vm";
import { AuthenticatedRoute } from "./components/common/AuthenticatedRoute";
import Page404 from "./pages/common/Page404";
import LoginPage from "./components/auth/Login";
import NavBar from "./components/common/NavBar";
import DashMainPage from "./pages/dash/DashMainPage";
import UsersPage from "./pages/users/UsersPage";
import CheckSpaceBar from "./components/common/CheckSpaceBar";
import UserProfilePage from "./pages/users/UserProfilePage";
import CategoryPage from "./pages/category/CategoryPage";
import OrdersPage from "./pages/orders/OrdersPage";
import ProductPage from "./pages/products/ProductPage";
import BannersPage from "./pages/banners/BannersPage";
import LocationGroupPage from "./pages/locations-group/LocationGroupPage";
import HomeFeedPage from "./pages/home-feeds/HomeFeedPage";
import ShopPage from "./pages/shops/ShopPage";

export interface RouteProps extends ClassProps { }

const Routes: React.FC<RouteProps> = ({ classes }) => {
  return (
    <Router>
      <div className="d-flex">
        <CssBaseline />

        <NavBar />

        <main className={classes.content}>
          {/* <div className={classes.contentContainer}> */}
          <CheckSpaceBar />
          <Switch>
            <Route path="/" exact component={LoginPage} />
            <AuthenticatedRoute
              path="/dashboard"
              exact
              component={DashMainPage}
            />
            <AuthenticatedRoute
              path="/shops"
              exact
              component={ShopPage}
            />
            <AuthenticatedRoute path="/users" exact component={UsersPage} />
            <AuthenticatedRoute
              path="/users/:userId/profile"
              exact
              component={UserProfilePage}
            />
            <AuthenticatedRoute
              path="/categories"
              exact
              component={CategoryPage}
            />
            <AuthenticatedRoute
              path="/products"
              exact
              component={ProductPage}
            />
            <AuthenticatedRoute path="/orders" exact component={OrdersPage} />
            <AuthenticatedRoute path="/banners" exact component={BannersPage} />
            <AuthenticatedRoute
              path="/locations-group"
              exact
              component={LocationGroupPage}
            />
            <AuthenticatedRoute
              path="/home-feeds"
              exact
              component={HomeFeedPage}
            />

            {/*<AuthenticatedRoute path='/videos' exact component={VideoMain} />*/}
            {/*<AuthenticatedRoute*/}
            {/*    path='/videos/:videoId'*/}
            {/*    exact*/}
            {/*    component={VideoDetail}*/}
            {/*/>*/}

            <Route path="" exact component={Page404} />
          </Switch>
        </main>
      </div>
    </Router>
  );
};

const styles = () =>
  createStyles({
    appBarSpacer: { height: 48 },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      padding: 16,
      backgroundColor: "##fbfbfb",
      //   marginTop: 48,
    },
  });
export default withStyles(styles)(Routes);
