import React from "react";
import LocationGroupMain from "../../components/location-group/LocationGroupMain";

const LocationGroupPage: React.FC = () => {
  return (
    <section>
      <LocationGroupMain />
    </section>
  );
};

export default LocationGroupPage;
