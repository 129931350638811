import React from "react";
import { IOrder, IUserAddress } from "../../../vm";
import {
  AppBar,
  Tabs,
  Tab,
  Grid,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import {
  ORDER_STATUS,
  PAYMENT_METHOD,
  DEFAULT_IMG_URL,
} from "../../../Constant";
import { ToastContext } from "../../common/ToastProvider";
import Loading from "../../common/Loading";
import { getOrderDetails } from "../../../services/OrderService";
import NoData from "../../common/NoData";
import { useHistory } from "react-router";

export interface ManageOrderDialogProps {
  orderId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    // margin: -16,
  },
  mainSection: {
    padding: 16,
  },
}));

const ManageOrderDialog: React.FC<ManageOrderDialogProps> = ({ orderId }) => {
  // custom hooks
  const classes = useStyles();
  const { showToast } = React.useContext(ToastContext);
  const history = useHistory();

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const [orderDetails, setOrderDetails] = React.useState({} as IOrder);
  const [isDataLoaded, setDataLoaded] = React.useState(false as boolean);

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getAOrderDetails();
    };
    asyncFunc();
  }, []);

  const getAOrderDetails = async () => {
    setLoading(true);
    const result = await getOrderDetails({
      orderId: orderId,
    });
    if (result?.success) {
      setOrderDetails(result.data);
      setDataLoaded(true);
    } else {
      showToast(
        result?.message || "Error while getting order details",
        "error"
      );
    }
    setLoading(false);
  };

  const getAddress = (data: IUserAddress) => {
    let address = "";
    if (data.landMark) {
      address = address + data.landMark + ", ";
    }
    if (data.area) {
      address = address + data.area;
    }
    if (data.addressLine1) {
      address = address + ", " + data.addressLine1;
    }
    if (data.landMark) {
      address = address + ", " + data.landMark;
    }
    if (data.city) {
      address = address + ", " + data.city;
    }
    return address;
  };

  const getPhoneDetails = (data: IUserAddress) => {
    let phone = "";
    if (data.phone) {
      phone = data.phone;
    }
    if (data.alternatePhone) {
      phone = phone + ",  " + data.alternatePhone;
    }
    return phone;
  };

  const goToProductPage = (id: string) => {
    history.push(`/products?_id=${id}`);
  }

  return (
    <main className={classes.mainContainer}>
      {isLoading && <Loading />}
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Overview" />
          <Tab label="Product Details" />
        </Tabs>
      </AppBar>
      <section className={classes.mainSection}>
        {value === 0 &&
          (isDataLoaded ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper className="p-16">
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      <Typography variant="subtitle2">
                        <span className="text-grey">Order ID - </span>{" "}
                        {orderDetails.orderNo}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2">
                        <span className="text-grey">Order Date - </span>{" "}
                        {moment(orderDetails.orderTime).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2">
                        <span className="text-grey">Invoice No - </span>{" "}
                        {orderDetails?.paymentDetail?.invoiceNo}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2">
                        {orderDetails.totalItem +
                          " items,  " +
                          PAYMENT_METHOD[orderDetails?.paymentMethod as string]}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="button">
                        {ORDER_STATUS[orderDetails.status as number]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className="p-16">
                  <Grid container spacing={0} direction="column">
                    <Grid item>
                      <Typography className="text-grey pb-12">
                        Shipping Details
                      </Typography>
                    </Grid>
                    <Divider />
                    <Grid item>
                      <Typography
                        className="pb-12 pt-12 fs-16"
                        variant="subtitle2"
                      >
                        {orderDetails.shippingAddress.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <Typography>
                        {getAddress(
                          orderDetails.shippingAddress as IUserAddress
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {orderDetails.shippingAddress.city +
                          " - " +
                          orderDetails.shippingAddress.area}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {"Phone Number: " +
                          getPhoneDetails(
                            orderDetails.shippingAddress as IUserAddress
                          )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper className="p-16">
                  <Grid container spacing={0} direction="column">
                    <Grid item>
                      <Typography className="text-grey pb-12">
                        Price Details
                      </Typography>
                    </Grid>
                    <Divider />
                    <Grid item className="pt-8">
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography>Selling Price</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {"₹" + orderDetails.totalPrice}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography>Price</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {"₹" + orderDetails.totalEffectivePrice}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography>Discount</Typography>
                        </Grid>
                        <Grid item>
                          <Typography style={{ color: "green" }}>
                            {"₹-" +
                              ((orderDetails?.totalPrice || 0) -
                                (orderDetails?.totalEffectivePrice || 0))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography>Shipping fee</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {"₹" + orderDetails?.deliveryCharge}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item className="pb-8">
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography>Shipping fee discount</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {"₹" +
                              ((orderDetails?.deliveryCharge || 0) -
                                (orderDetails?.effectiveDeliveryCharge || 0))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid item className="pt-8">
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography variant="subtitle2">
                            Total Amount
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle2"
                            style={{ color: "red" }}
                          >
                            {"₹" +
                              ((orderDetails?.totalEffectivePrice || 0) +
                                (orderDetails?.effectiveDeliveryCharge || 0))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <NoData msg={"Invalid Order Id"} />
          ))}
        {value === 1 && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className="p-16">
                <Grid container spacing={2}>
                  {orderDetails.products.map((d, index) => (
                    <Grid item xs={12} sm={12} md={12} lg={12} className="chip-container">
                      <Grid container spacing={2} alignItems="center" className="pointer" onClick={() => goToProductPage(d.productId)}>
                        <Grid item xs={12} sm={6} md={5} lg={5}>
                          <Paper className="p-8">
                            {d.imageUrl ? (
                              <img
                                src={d.imageUrl}
                                alt={d.title}
                                className="order-img"
                              />
                            ) : (
                              <img
                                src={DEFAULT_IMG_URL}
                                alt={d.title}
                                className="order-img"
                              />
                            )}
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={7} lg={7}>
                          <Grid container spacing={1} direction="column">
                            <Grid item className="pt-10">
                              <Typography variant="subtitle2">
                                {d.title}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {"₹" + d.buyingPriceRate}
                            </Grid>
                            <Grid item>{d.unitLabel}</Grid>
                            <Grid item>{d.quantity + " items"}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {orderDetails.products.length - 1 !== index && (
                        <Divider className="custom-divider" />
                      )}
                      {d.subCategories && d.subCategories.length > 0 && (
                        <div className="custom-chip">
                          <Typography variant="caption">{d.subCategories[0].title}</Typography>
                        </div>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </section>
    </main>
  );
};

export default ManageOrderDialog;
