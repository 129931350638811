import React from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Formik } from "formik";
import { getUsers } from "../../../services/UsersService";
import { IUser } from "../../../vm";
import Loading from "../../common/Loading";
import { ToastContext } from "../../common/ToastProvider";
import { getFullName } from "../../../services/UtilServices";
import { updateUser } from "../../../services/UsersService";
import UserForm from "../UserForm";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepPurple } from "@material-ui/core/colors";
import NoData from "../../common/NoData";
import * as yup from "yup";

const userSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email().required("required"),
  phone: yup.string().length(10).required("required"),
});

export interface UserProfileMainProps {
  userId: string;
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  //   justifyContent: "center",
  //   // "& > *": {
  //   //   margin: theme.spacing(1),
  //   // },
  // },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const UserProfileMain: React.FC<UserProfileMainProps> = ({ userId }) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const classes = useStyles();

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [isDataLoaded, setDataLoaded] = React.useState(false as boolean);
  const [user, setUser] = React.useState({} as IUser);
  const [onEdit, setOnEdit] = React.useState(false as boolean);

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getAUser();
    };
    asyncFunc();
  }, []);

  const getAUser = async () => {
    setLoading(true);
    const result = await getUsers({ userId: userId });
    if (result && result.success && result.data.length > 0) {
      setUser(result.data[0]);
      setDataLoaded(true);
    } else {
      showToast(result?.message || "Error while getting user details", "error");
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      {isDataLoaded && user !== undefined ? (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h5">User Profile</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className="padding-16">
              <Grid
                container
                spacing={3}
                direction="row"
                justify="space-between"
              // alignItems="center"
              >
                <Grid item lg={3} md={4} sm={12} xs={12}>
                  <Grid
                    container
                    // spacing={1}
                    direction="column"
                    alignItems="center"
                  >
                    <Grid item>
                      <Avatar
                        className={`${classes.large} ${classes.purple} fs-50`}
                      >
                        {user.firstName.charAt(0) + user.lastName.charAt(0)}
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">{getFullName(user)}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{user.email}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{user.phone}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={9} md={8} sm={12} xs={12}>
                  <Formik
                    enableReinitialize
                    initialValues={user}
                    validationSchema={userSchema}
                    onSubmit={async (values: IUser, { setSubmitting }) => {
                      setLoading(true);
                      let obj = { ...values };
                      // obj = removeNulls(obj);

                      const res = await updateUser(obj);
                      setLoading(false);
                      if (res?.success) {
                        setUser(res.data);
                        setOnEdit(false);
                        showToast(`User updated successfully`, "success");
                      } else {
                        showToast(
                          res?.message || `Error while updating user`,
                          "error"
                        );
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit /* and other goodies */,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                          {!onEdit && (
                            <Grid item xs={12}>
                              <Grid container justify="flex-end">
                                <Grid item>
                                  <Button
                                    onClick={() => setOnEdit(true)}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                  >
                                    Edit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <UserForm
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            onEdit={onEdit}
                          />

                          {onEdit && (
                            <Grid item xs={12}>
                              <Grid container spacing={2} justify="flex-end">
                                <Grid item>
                                  <Button
                                    onClick={() => setOnEdit(false)}
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                  // className={classes.button}
                                  // startIcon={<SaveIcon />}
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  // className={classes.button}
                                  // startIcon={<Save />}
                                  >
                                    Save
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        // <Grid container>
        //   <Grid item xs={12}>
        //     <Paper className="padding-16">
        //       <Grid container justify="center" alignItems="center">
        //         <Grid item>
        //           <Typography>Something went wrong</Typography>
        //         </Grid>
        //       </Grid>
        //     </Paper>
        //   </Grid>
        // </Grid>
        <NoData msg="Something went wrong" />
      )}
    </React.Fragment>
  );
};

export default UserProfileMain;
