import * as React from "react";
import CustomDrawer from "../common/CustomerDrawer";
import { IUser } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { Formik, FormikErrors } from "formik";
import { updateUser, addUser } from "../../services/UsersService";
import {
  Grid,
  Button,
} from "@material-ui/core";
import { removeNulls } from "../../services/UtilServices";
import { Loading } from "../common/Loading";
import UserForm from "./UserForm";

// const userSchema = yup.object().shape({
//   firstName: yup.string().required("required"),
//   lastName: yup.string().required("required"),
//   email: yup.string().email().required("required"),
//   phone: yup.string().length(10).required("required"),
//   firstName: yup.string().required("required"),
// });

export interface ManageUserDialog {
  onClose: Function;
  data?: IUser;
}

const ManageUserDialog: React.FC<ManageUserDialog> = ({ data, onClose }) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [user, setUser] = React.useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    regType: "admin",
    gender: "male",
    // dob: "",
    phone: "",
    password: "",
    isEnabled: true,
    isDeleted: false,
    roleId: 0,
    regForm: "manual",
    ...data,
  } as IUser);

  const onDialogClose = (data?: IUser) => {
    onClose(data);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer
        title={user._id ? "Edit User" : "Add User"}
        handleOpen={true}
        onClose={() => onDialogClose()}
      >
        <section className="padding-16">
          <Formik
            enableReinitialize
            initialValues={user}
            validate={(values) => {
              let errors: FormikErrors<IUser> = {};
              if (!values.firstName) {
                errors.firstName = "Required";
              }
              if (!values.lastName) {
                errors.lastName = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              }
              if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.phone) {
                errors.phone = "Required";
              } else if (String(values.phone).length !== 10) {
                errors.phone = "Please enter valid phone";
              }
              if (!values._id) {
                if (!values.password) {
                  errors.password = "Required";
                } else if (values.password.length < 4) {
                  errors.password =
                    "Password must be at-least 4 characters long";
                }
              }
              return errors;
            }}
            onSubmit={async (values: IUser, { setSubmitting }) => {
              setLoading(true);
              let res;
              let obj = { ...values };
              obj = removeNulls(obj);
              if (values._id) {
                res = await updateUser(obj);
              } else {
                res = await addUser(obj);
              }
              setLoading(false);
              if (res?.success) {
                showToast(
                  `User ${values._id ? "updated" : "created"} successfully`,
                  "success"
                );
                onDialogClose(res.data);
              } else {
                showToast(
                  res?.message ||
                  `Error while ${values._id ? "updating" : "creating"} user`,
                  "error"
                );
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit /* and other goodies */,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <UserForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    onEdit={true}
                  />

                  <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item>
                        <Button
                          onClick={() => onClose()}
                          variant="outlined"
                          color="secondary"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {values._id ? "Update" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ManageUserDialog;
