import React from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Fab,
} from "@material-ui/core";
import { ISubCategory, ISubCategorySearchObj } from "../../../vm";
import { ConfirmDialogContext } from "../../common/ConfirmDialogProvider";
import Loading from "../../common/Loading";
import { ToastContext } from "../../common/ToastProvider";
import {
  Pencil,
  TrashCan,
  OpenInNew,
  ChevronLeft,
  ChevronRight,
} from "mdi-material-ui";
import SubCategoryForm from "./SubCategoryForm";
import NoData from "../../common/NoData";
import {
  getSubCategories,
  deleteASubCategory,
} from "../../../services/SubCategoryService";
import CustomerDrawer from "../../common/CustomerDrawer";
import { DEFAULT_IMG_URL, STATUS_DICT } from "../../../Constant";

export interface SubCategoryMainProps {
  categoryId: string;
  onClose: Function;
}

const SubCategoryMain: React.FC<SubCategoryMainProps> = ({
  categoryId,
  onClose,
}) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [subCategory, setSubCategory] = React.useState([] as ISubCategory[]);
  const [manageSubCategoryDialog, setManageSubCategoryDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });
  const [searchObj, setSearchObj] = React.useState({
    offset: 0,
    limit: 10,
    search: "",
    categoryId: categoryId,
    // status: 9,
    showAllData: true,
  } as ISubCategorySearchObj);
  const [hasMoreCategory, setHasMoreCategory] = React.useState(
    false as boolean
  );

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getAllSubCategory();
    };
    asyncFunc();
  }, []);

  const getAllSubCategory = async (search?: any) => {
    let serchObj = { ...searchObj };
    if (search != null) {
      serchObj = { ...serchObj, ...search };
    }
    setLoading(true);
    const result = await getSubCategories(serchObj);
    if (result && result.success) {
      setSubCategory(result.data);
      setSearchObj(serchObj);
      setHasMoreCategory(result.data.length === serchObj.limit);
    } else {
      showToast(
        result?.message || "Error while getting sub-categories",
        "error"
      );
    }
    setLoading(false);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = subCategory[editIndex];
    }
    setManageSubCategoryDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleClose = (data?: ISubCategory) => {
    let categoryList = [...subCategory];
    if (data) {
      if (manageSubCategoryDialog.editIndex !== -1) {
        categoryList[manageSubCategoryDialog.editIndex] = data;
      } else {
        categoryList.unshift(data);
      }
    }
    setSubCategory(categoryList);
    setManageSubCategoryDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const deleteSubCategory = (index: number) => {
    showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
      let categoryList = [...subCategory];
      setLoading(true);
      let result = await deleteASubCategory(categoryList[index]._id as string);
      setLoading(false);
      if (result?.success) {
        showToast("User sub-category deleted successfully", "success");
        categoryList.splice(index, 1);
        setSubCategory(categoryList);
      } else {
        showToast(
          result?.message || "Error while deleting sub-category",
          "error"
        );
      }
    });
  };

  const getSubCategoriesFrom = async (offset: number) => {
    let search = { ...searchObj };
    search.offset = offset;
    await getAllSubCategory(search);
  };

  const onSearch = async () => {
    let serchObj = { ...searchObj };
    serchObj.offset = 0;
    await getAllSubCategory(serchObj);
  };

  const onDialogClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomerDrawer
        title="Sub-Categories"
        handleOpen={true}
        onClose={() => onDialogClose()}
        variant="large"
      >
        <section className="padding-16">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddDialogOpen(true)}
                  >
                    Add Sub-Category
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Sub Title</TableCell>
                      <TableCell>Description</TableCell>
                      {/* <TableCell>Is Enabled</TableCell> */}
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subCategory.length > 0 ? (
                      subCategory.map((d, index) => (
                        <TableRow key={d._id}>
                          <TableCell>
                            {d.imageUrl ? (
                              <img
                                src={d.imageUrl}
                                alt="log"
                                className="sub-category-img"
                              />
                            ) : (
                              <img
                                src={DEFAULT_IMG_URL}
                                alt="log"
                                className="sub-category-img"
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Typography>{d.title}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{d.subTitle}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {d.description ? d.description : "NA"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <Typography>{STATUS_DICT[d.status]}</Typography>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Grid container>
                              <Grid item>
                                <Tooltip title="Update Sub-Category">
                                  <IconButton
                                    size="medium"
                                    color="primary"
                                    onClick={() =>
                                      handleAddDialogOpen(true, index)
                                    }
                                  >
                                    <Pencil />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title="Delete Sub-Category">
                                  <IconButton
                                    size="medium"
                                    color="secondary"
                                    onClick={() => deleteSubCategory(index)}
                                  >
                                    <TrashCan />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <NoData msg="No Sub-Category found" isTable={true} />
                          {/* <br />
                          <Typography variant="subtitle1">
                            No Sub-Category found
                          </Typography>
                          <br /> */}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                justify="flex-end"
                className="padding-16"
              >
                {(searchObj?.offset || 0) > 0 && (
                  <Grid item>
                    <Fab
                      size="small"
                      color="primary"
                      onClick={() =>
                        getSubCategoriesFrom(
                          (searchObj?.offset || 0) - (searchObj?.limit || 0)
                        )
                      }
                    >
                      <ChevronLeft />
                    </Fab>
                  </Grid>
                )}
                {hasMoreCategory && (
                  <Grid item>
                    <Fab
                      size="small"
                      color="primary"
                      onClick={() =>
                        getSubCategoriesFrom(
                          (searchObj?.offset || 0) + (searchObj?.limit || 0)
                        )
                      }
                    >
                      <ChevronRight />
                    </Fab>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </section>
      </CustomerDrawer>
      {manageSubCategoryDialog.isOpen && (
        <CustomerDrawer
          title={
            manageSubCategoryDialog?.data?._id
              ? "Edit Sub-Category"
              : "Add Sub-Category"
          }
          handleOpen={true}
          onClose={() => handleClose()}
        >
          <SubCategoryForm
            onClose={handleClose}
            data={manageSubCategoryDialog.data}
            categoryId={categoryId}
          />
        </CustomerDrawer>
      )}
    </React.Fragment>
  );
};

export default SubCategoryMain;
