import { Grid, Typography } from "@material-ui/core";
import * as React from "react";

export interface NoDataProps {
  msg?: string;
  isTable?: boolean;
}

const NoData: React.FC<NoDataProps> = ({ msg, isTable }) => {
  return isTable ? (
    <>
      <br />
      <Typography variant="subtitle1">{msg || "No Data Found"}</Typography>
      <br />
    </>
  ) : (
    <Grid container spacing={2} justify="center">
      <Grid item>
        <br />
        <Typography variant="subtitle1">{msg || "No Data Found"}</Typography>
        <br />
      </Grid>
    </Grid>
  );
};

export default NoData;
