import React from "react";
import { IHomeFeed, IProduct } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import {
  Grid,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Divider,
  Typography,
} from "@material-ui/core";
import { Loading } from "../common/Loading";
import { ReactSelect } from "../common/Library";
import { updateAHomeFeed } from "../../services/HomeFeedService";
import NoData from "../common/NoData";
import { File, Pencil, TrashCan } from "mdi-material-ui";

export interface IHomeFeedProductFormProps {
  data?: IHomeFeed;
  onClose: any;
  productList: IProduct[];
  productNameDict: any;
}

const HomeFeedProductForm: React.FC<IHomeFeedProductFormProps> = ({
  data,
  onClose,
  productList,
  productNameDict,
}) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const [isLoading, setLoading] = React.useState(false as boolean);
  const [homeFeed, setHomeFeed] = React.useState({
    title: "",
    productIds: [],
    status: 0,
    ...data,
  } as IHomeFeed);
  const [selectedProductIds, setSelectedProductIds] = React.useState(
    [] as string[]
  );
  const [products, setProducts] = React.useState(data?.productIds as string[]);
  const [isOnEdit, setIsOnEdit] = React.useState(false as boolean);

  const onDialogClose = (data?: IHomeFeed) => {
    onClose(data);
  };

  const onProductChange = (productIds: any) => {
    setSelectedProductIds(productIds);
  };

  const onAddProductToList = () => {
    let productIds = [...products];
    productIds = [...productIds, ...selectedProductIds];
    setProducts(productIds);
    setSelectedProductIds([]);
  };

  const onRemoveProduct = (index: number) => {
    const productList = [...products];
    productList.splice(index, 1);
    setProducts(productList);
  };

  const onSaveproduct = async () => {
    let data = { ...homeFeed };
    data.productIds = [...products];
    // if (data.productIds.length === 0) {
    //   showToast("Add at least one product before save", "error");
    // } else {
    setLoading(true);
    const result = await updateAHomeFeed(data);
    if (result?.success) {
      onDialogClose(result.data);
      showToast("Product added to home feed successfully", "success");
    } else {
      showToast(
        result?.message || "Error while adding product to home feed",
        "error"
      );
    }
    setLoading(false);
    // }
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="padding-16">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2} justify="flex-end">
              {isOnEdit ? (
                <>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setIsOnEdit(false);
                        setProducts(homeFeed.productIds);
                        setSelectedProductIds([]);
                      }}
                      variant="outlined"
                      color="secondary"
                      type="button"
                      size="small"
                      //   startIcon={<Close />}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={onSaveproduct}
                      variant="contained"
                      color="primary"
                      type="button"
                      size="small"
                      startIcon={<File />}
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <Button
                    onClick={() => setIsOnEdit(true)}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Pencil />}
                  >
                    Edit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {isOnEdit && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2} alignItems="center" wrap="wrap">
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  <ReactSelect
                    value={selectedProductIds}
                    isClearable={true}
                    isMulti={true}
                    options={productList
                      .filter((d) => !products.includes(d._id as string))
                      .map((d) => {
                        return { value: d._id as string, label: d.title };
                      })}
                    onChange={onProductChange}
                    label={"Products"}
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={onAddProductToList}
                    variant="contained"
                    color="secondary"
                    //   type="button"
                    disabled={selectedProductIds.length === 0}
                  >
                    Add Product
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={1} direction="column">
              {products.length > 0 && (
                <Grid item>
                  <Typography variant="h6" className="fs-16">
                    Selected Products
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Paper>
                  {products && products.length > 0 ? (
                    <List>
                      {products.map((d, index) => (
                        <>
                          <ListItem>
                            <ListItemText primary={productNameDict[d]} />
                            {isOnEdit && (
                              <ListItemSecondaryAction>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => onRemoveProduct(index)}
                                  edge="end"
                                >
                                  <TrashCan />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                          {/* <Divider /> */}
                        </>
                      ))}
                    </List>
                  ) : (
                    <NoData msg="No Product Found" />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
};

export default HomeFeedProductForm;
