import React from "react";
import { IHomeFeed } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { Formik } from "formik";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
} from "@material-ui/core";
import { Loading } from "../common/Loading";
import * as yup from "yup";
import { CustomSelect, CustomTextField } from "../common/Library";
import { STATUS_DICT } from "../../Constant";
import { removeNulls } from "../../services/UtilServices";
import { addAHomeFeed, updateAHomeFeed } from "../../services/HomeFeedService";

const homeFeedSchema = yup.object().shape({
  title: yup.string().required("required"),
});

export interface HomeFeedFormProps {
  onClose: Function;
  data?: IHomeFeed;
}

const HomeFeedForm: React.FC<HomeFeedFormProps> = ({ onClose, data }) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const [isLoading, setLoading] = React.useState(false as boolean);
  const [homeFeed, setHomeFeed] = React.useState({
    title: "",
    productIds: [],
    status: 0,
    ...data,
  } as IHomeFeed);

  const onDialogClose = (data?: IHomeFeed) => {
    onClose(data);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="padding-16">
        <Formik
          enableReinitialize
          initialValues={homeFeed}
          validationSchema={homeFeedSchema}
          onSubmit={async (values: IHomeFeed, { setSubmitting }) => {
            setLoading(true);
            let res;
            let obj = { ...values };
            obj = removeNulls(obj);
            if (values._id) {
              res = await updateAHomeFeed(obj);
            } else {
              res = await addAHomeFeed(obj);
            }
            setLoading(false);
            if (res?.success) {
              showToast(
                `Home Feed ${values._id ? "updated" : "created"} successfully`,
                "success"
              );
              onDialogClose(res.data);
            } else {
              showToast(
                res?.message ||
                  `Error while ${
                    values._id ? "updating" : "creating"
                  } home feed`,
                "error"
              );
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit /* and other goodies */,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField name="title" label="Title" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomSelect
                    name="status"
                    label="Status"
                    menuList={Object.keys(STATUS_DICT).map((d) => {
                      return { value: d, label: STATUS_DICT[d] };
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => onClose()}
                        variant="outlined"
                        color="secondary"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        {values._id ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </section>
    </React.Fragment>
  );
};

export default HomeFeedForm;
