import { Grid } from "@material-ui/core";
import React from "react";
import async from "react-select/async";
import { getDashboardData } from "../../services/DashboardService";
import { IDashboardData } from "../../vm";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import CommonDashboard from "./CommonDashboard";
import DashboardTotalCounts from "./DashboardTotalCounts";
import OrdersDashboard from "./OrdersDashboard";

export interface DashboardMainProps { }

const DashboardMain: React.FC<DashboardMainProps> = () => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [dashboardData, setDashboardData] = React.useState({
    totalCustomer: 0,
    totalCategory: 0,
    totalProduct: 0,
    totalOrder: 0,
    totalHomeFeed: 0,
    totalLocationGroup: 0,
    users: [],
    products: [],
    categories: [],
    orderStatus: [],
    deliveryStatus: [],
  } as IDashboardData);

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getDashboardDetailsData();
    };
    asyncFunc();
  }, []);

  const getDashboardDetailsData = async () => {
    setLoading(true);
    const result = await getDashboardData();
    if (result?.success) {
      // checking category
      if (result.data.categories.length < 3) {
        const categories: any = [];
        [1, 0, -1].forEach((d) => {
          let category = result.data.categories.find((ele) => ele._id === d);
          if (category) {
            categories.push(category);
          } else {
            categories.push({ _id: d, count: 0 });
          }
        });
        result.data.categories = categories;
      }
      // checking product
      if (result.data.products.length < 4) {
        const products: any = [];
        [1, 0, -1, -2].forEach((d) => {
          let product = result.data.products.find((ele) => ele._id === d);
          if (product) {
            products.push(product);
          } else {
            products.push({ _id: d, count: 0 });
          }
        });
        result.data.products = products;
      }
      // checking order status
      const orderStatus: any = [];
      [1, -1, 2, -3, 5, -5].forEach((d) => {
        let order = result.data.orderStatus.find((ele) => ele._id === d);
        if (order) {
          orderStatus.push(order);
        } else {
          orderStatus.push({ _id: d, count: 0 });
        }
      });
      result.data.orderStatus = orderStatus;
      setDashboardData(result.data);
    } else {
      showToast(
        result?.message || "Error while getting dashboard data",
        "error"
      );
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={1} style={{ marginTop: "4rem" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DashboardTotalCounts data={dashboardData} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-16">
          <OrdersDashboard />
          {/* <CommonDashboard
            data={dashboardData.orderStatus}
            title="Orders"
            isOrder={true}
            isProduct={false}
          /> */}
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12} className="pt-16">
          <CommonDashboard
            data={dashboardData.categories}
            title="Categories"
            isOrder={false}
            isProduct={false}
          />
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12} className="pt-16">
          <CommonDashboard
            data={dashboardData.products}
            title="Products"
            isOrder={false}
            isProduct={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DashboardMain;
