import * as React from "react";
import { IProduct, IProductOptions } from "../../../vm";
import { deleteAProduct, getAProductOptions, removeAProductOptions } from "../../../services/ProductService";
import {
    Button,
    Chip,
    Grid, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@material-ui/core";
//import {DEFAULT_IMG_URL, STATUS_DICT} from "../../../Constant";
import { Pencil, TrashCan, ViewList } from "mdi-material-ui";
import NoData from "../../common/NoData";
import CustomerDrawer from "../../common/CustomerDrawer";
//import ProductForm from "../ProductForm";
import { ProductOptionForm } from "./ProductOptionForm";
import { ConfirmDialogContext } from "../../common/ConfirmDialogProvider";
import { ToastContext } from "../../common/ToastProvider";
import { Loading } from "../../common/Loading";


export interface ProductOptionMainProps {
    product?: IProduct;
    productId: string;
}

export const ProductOptionMain: React.FC<ProductOptionMainProps> = ({ productId }) => {
    // → hooks
    const { showToast } = React.useContext(ToastContext);
    const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
    // → local state
    const [isLoading, setLoading] = React.useState(false as boolean);
    const [options, setOptions] = React.useState<Array<IProductOptions>>([]);
    const [manageOptionDialog, setManageOptionDialog] = React.useState({
        isOpen: false,
        data: {},
        editIndex: -1,
    } as {
        isOpen: boolean;
        data: any;
        editIndex: number;
    });
    // → local hooks
    React.useEffect(() => {
        getOptions().then()
    }, []);

    const getOptions = async () => {
        const result = await getAProductOptions(productId);
        if (result?.success) {
            setOptions(result.data);
        }
    }
    const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
        let data: any = undefined;
        if (editIndex !== undefined) {
            data = options[editIndex];
        }
        setManageOptionDialog({
            isOpen: isOpen,
            data: data,
            editIndex: editIndex !== undefined ? editIndex : -1,
        });
    }

    const handleClose = (data?: IProductOptions) => {
        let optionList = [...options];
        if (data) {
            if (manageOptionDialog.editIndex !== -1) {
                optionList[manageOptionDialog.editIndex] = data;
            } else {
                optionList.unshift(data);
            }
        }
        setOptions(optionList);
        setManageOptionDialog({
            isOpen: false,
            data: undefined,
            editIndex: -1,
        });
    };

    const deleteProduct = (index: number) => {
        showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
            let optionList = [...options];
            setLoading(true);
            let result = await removeAProductOptions(optionList[index]._id as string, productId);
            setLoading(false);
            if (result?.success) {
                showToast("product Option deleted successfully", "success");
                optionList.splice(index, 1);
                setOptions(optionList);
            } else {
                showToast(result?.message || "Error while deleting product option", "error");
            }
        });
    };


    return (
        <div className={"p-16"}>
            {isLoading && <Loading />}
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddDialogOpen(true)}
                            >
                                Add Product
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Price Unit</TableCell>
                                    <TableCell>MRP</TableCell>
                                    <TableCell>Selling Price</TableCell>
                                    <TableCell>Label</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {options.length > 0 ? (
                                    options.map((op, index) => (
                                        <TableRow key={op._id}>
                                            <TableCell>
                                                <Typography>{index + 1}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{op.priceUnit}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{op.mrp}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{op.sellingPrice}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{op.unitLabel}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item>
                                                        <Tooltip title="Update Product Option">
                                                            <IconButton
                                                                size="medium"
                                                                color="primary"
                                                                onClick={() =>
                                                                    handleAddDialogOpen(true, index)
                                                                }
                                                            >
                                                                <Pencil />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>

                                                    <Grid item>
                                                        <Tooltip title="Delete Options">
                                                            <IconButton
                                                                size="medium"
                                                                color="secondary"
                                                                onClick={() => deleteProduct(index)}
                                                            >
                                                                <TrashCan />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (<TableRow>
                                    <TableCell colSpan={12} align="center">
                                        <NoData msg="No Product found" isTable={true} />
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {manageOptionDialog.isOpen && (
                <CustomerDrawer
                    title={
                        manageOptionDialog?.data?._id ? "Edit Product Option" : "Add Product Option"
                    }
                    handleOpen={true}
                    onClose={() => handleClose()}
                >
                    <ProductOptionForm
                        onClose={handleClose}
                        productId={productId}
                        option={manageOptionDialog.data}
                    />
                </CustomerDrawer>
            )}
        </div>
    )
}
