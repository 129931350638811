import { httpClient } from "./UtilServices";
import {
  IStandardAPIResponse,
  ISubCategory,
  ISubCategorySearchObj,
} from "../vm";

export const addASubCategory = async (
  category: ISubCategory
): Promise<IStandardAPIResponse<ISubCategory>> => {
  try {
    let res = await httpClient<ISubCategory>(`sub-categories`, "PUT", category);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateASubCategory = async (
  category: ISubCategory
): Promise<IStandardAPIResponse<ISubCategory>> => {
  try {
    let res = await httpClient<ISubCategory>(
      `sub-categories/${category._id}`,
      "PATCH",
      category
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const deleteASubCategory = async (
  categoryId: string
): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient(`sub-categories/${categoryId}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getSubCategories = async (
  searchObj: ISubCategorySearchObj
): Promise<IStandardAPIResponse<Array<ISubCategory>>> => {
  try {
    let res = await httpClient<Array<ISubCategory>>(
      `sub-categories`,
      "GET",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};
