import React from "react";
import BannerMain from "../../components/banners/BannerMain";

const BannersPage: React.FC = () => {
  return (
    <section>
      <BannerMain />
    </section>
  );
};

export default BannersPage;
