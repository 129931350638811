import { httpClient, handleErrorResp } from './UtilServices';
import { ILogin, IStandardAPIResponse } from '../vm';

export async function loginToApp(
    cred: ILogin
): Promise<IStandardAPIResponse<any>> {
    try {
        const res = await httpClient<any>(`auth-admin`, 'POST', cred);
        return res;
    } catch (err) {
        return handleErrorResp(err);
    }
}
