import React from "react";
import HomeFeedMain from "../../components/home-feeds/HomeFeedMain";

const HomeFeedPage: React.FC = () => {
  return (
    <section>
      <HomeFeedMain />
    </section>
  );
};

export default HomeFeedPage;
