import * as React from "react";
import * as yup from "yup";
import { Loading } from "../../common/Loading";
import { Formik } from "formik";
import { IProductOptions } from "../../../vm";
import { addAProductOptions, updateProductOptions } from "../../../services/ProductService";
import {
    Button,
    Grid,
} from "@material-ui/core";
import { CustomSelect, CustomTextField } from "../../common/Library";
import { PRODUCT_UNIT } from "../../../Constant";
import { ToastContext } from "../../common/ToastProvider";

interface ProductOptionFormProps {
    onClose?: Function;
    option?: IProductOptions;
    productId?: string;
}

const productOptionSchema = yup.object().shape({
    // images: yup.array().items(yup.string()),
    mrp: yup.number(),
    sellingPrice: yup.number(),
    priceUnit: yup.string().default("piece"),
    // unitLabel: yup.string().allow(""),
})

export const ProductOptionForm: React.FC<ProductOptionFormProps> = ({ onClose, productId, option }) => {
    // custom hooks
    const { showToast } = React.useContext(ToastContext);
    // → local state
    const [isLoading, setLoading] = React.useState(false);

    const onCloseOrCancel = () => {
        onClose && onClose();
    }

    const onDialogClose = (data?: IProductOptions) => {
        onClose && onClose(data);
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="padding-16">
                <Formik
                    enableReinitialize
                    initialValues={option || { mrp: 0, priceUnit: "", sellingPrice: 0, unitLabel: "", images: [] }}
                    validationSchema={productOptionSchema}
                    onSubmit={async (values: IProductOptions, { setSubmitting }) => {
                        let res;
                        let obj = { ...values };
                        if (productId) {
                            obj["productId"] = productId;
                        }
                        setLoading(true);
                        if (obj._id) {
                            res = await updateProductOptions(obj._id as string, productId as string, obj);
                        } else {
                            res = await addAProductOptions(productId as string, obj)
                        }
                        if (res?.success) {
                            showToast(
                                `Product ${obj._id ? "updated" : "created"} successfully`,
                                "success"
                            );
                            onDialogClose(res.data);
                        } else {
                            showToast(
                                res?.message ||
                                `Error while ${values._id ? "updating" : "creating"} product`,
                                "error"
                            );
                        }
                        setLoading(false);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit /* and other goodies */,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <CustomSelect
                                        name="priceUnit"
                                        label="Price Unit"
                                        menuList={Object.keys(PRODUCT_UNIT).map((d) => {
                                            return { value: d, label: PRODUCT_UNIT[d] };
                                        })}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <CustomTextField name="unitLabel" label="Unit Label" />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <CustomTextField name="mrp" label="MRP" type="number" />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <CustomTextField
                                        name="sellingPrice"
                                        label="Selling Price"
                                        type="number"
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <Grid container spacing={2} justify="flex-end">
                                        <Grid item>
                                            <Button
                                                onClick={() => onCloseOrCancel()}
                                                variant="outlined"
                                                color="secondary"
                                                type="button"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" type="submit">
                                                {values._id ? "Update" : "Add"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </section>
        </React.Fragment>
    )
}
