import { httpClient } from "./UtilServices";
import {
  IStandardAPIResponse,
  IDashboardData,
  IOrderDashboardSearch,
  IOrderDashboardData,
} from "../vm";

export const getDashboardData = async (): Promise<
  IStandardAPIResponse<IDashboardData>
> => {
  try {
    let res = await httpClient<IDashboardData>(`dashboard-data`, "GET");
    return res;
  } catch (err: any) {
    return err;
  }
};

export const getOrdersDashboardData = async (
  obj: IOrderDashboardSearch
): Promise<IStandardAPIResponse<IOrderDashboardData>> => {
  try {
    let res = await httpClient<IOrderDashboardData>(
      `dashboard-data/orders`,
      "GET",
      obj
    );
    return res;
  } catch (err: any) {
    return err;
  }
};
