import React from "react";
import CustomDrawer from "../common/CustomerDrawer";
import { ICategory } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { Formik } from "formik";
import {
    Grid,
    FormControl,
    TextField,
    FormControlLabel,
    Switch,
    InputLabel,
    Select,
    Button,
    Typography,
    MenuItem,
    Checkbox,
    ListItemText,
    GridList,
    GridListTile,
    GridListTileBar,
    IconButton,
} from "@material-ui/core";
import {
    debounce,
    getFileByBase64,
    getSignedUrl,
    parseJwt,
    removeNulls,
    uploadFile,
} from "../../services/UtilServices";
import { Loading } from "../common/Loading";
import { addACategory, updateACategory } from "../../services/CategoryService";
import * as yup from "yup";
import { CustomSelect, CustomSwitch, CustomTextField } from "../common/Library";
import ImageCropper from "../common/ImageCropper";
import { Delete } from "mdi-material-ui";
import { STATUS_DICT } from "../../Constant";

const categorySchema = yup.object().shape({
    title: yup.string().required("required"),
    slug: yup.string().required("required"),
    // imageUrl: yup.string().required("required"),
});

export interface CategoryFormProps {
    onClose: Function;
    data?: ICategory;
}

const CategoryForm: React.FC<CategoryFormProps> = ({ onClose, data }) => {
    // custom hooks
    const { showToast } = React.useContext(ToastContext);

    const [isLoading, setLoading] = React.useState(false as boolean);
    const [category, setCategory] = React.useState({
        title: "",
        slug: "",
        image: "",
        imageUrl: "",
        // isEnabled: false,
        isDeleted: false,
        deletedImage: "",
        description: "",
        status: 0,
        // deliveryTime: "",
        // dontAllowToOrderWithOtherCat: false,
        isShowInAppDashBoard: false,
        ...data,
    } as ICategory);
    const [selectedImage, setSelectedImage] = React.useState(undefined as any);
    const [manageImageCropDialog, setManageImageCropDialog] = React.useState({
        isOpen: false,
        file: undefined,
        crop: {
            unit: "%",
            width: 60,
            aspect: 4 / 4,
        },
    } as {
        isOpen: boolean;
        file?: any;
        crop?: any;
    });

    const onDialogClose = (data?: ICategory) => {
        onClose(data);
    };

    const handleFileChange = (files: any) => {
        if (files[0].type.includes("image/")) {
            setSelectedImage(files[0]);
            handleOpenImageCropDialog(files[0]);
        } else {
            showToast("Select only images", "error");
        }
    };

    const uploadAFile = async (fileName: any, file: any) => {
        setLoading(true);
        let result = await getSignedUrl([fileName], file.type);
        if (result.success) {
            await uploadFile(result.data[0], file);
            return fileName;
        } else {
            showToast(`Error while uploading image`, "error");
        }
        return undefined;
    };

    const handleOpenImageCropDialog = (file: any) => {
        setManageImageCropDialog({ ...manageImageCropDialog, isOpen: true, file });
    };

    const handleCloseImageCropDialog = async (data?: any) => {
        let selectedImg = undefined;
        if (data) {
            selectedImg = await getFileByBase64(data, selectedImage.name);
        }
        setManageImageCropDialog({
            ...manageImageCropDialog,
            file: undefined,
            isOpen: false,
        });
        setSelectedImage(selectedImg);
    };

    const deleteImage = (deletedImage: string, values: any) => {
        setCategory({
            ...values,
            imageUrl: "",
            image: "",
            deletedImage: deletedImage,
        });
    };

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            <section className="padding-16">
                <Formik
                    enableReinitialize
                    initialValues={category}
                    validationSchema={categorySchema}
                    onSubmit={async (values: ICategory, { setSubmitting }) => {
                        let res;
                        let obj = { ...values };
                        // obj = removeNulls(obj);
                        setLoading(true);
                        if (obj._id) {
                            if (selectedImage) {
                                let name =
                                    `categories/${obj._id}/` +
                                    new Date().getTime() +
                                    "-" +
                                    selectedImage.name.toLowerCase().replace(/ /g, "");
                                let fileName = await uploadAFile(name, selectedImage);
                                if (fileName) {
                                    obj.image = fileName;
                                }
                            }
                            res = await updateACategory(obj);
                        } else {
                            res = await addACategory(obj);
                            if (res?.success) {
                                if (selectedImage) {
                                    let data = { ...res.data };
                                    let name =
                                        `categories/${res.data._id}/` +
                                        new Date().getTime() +
                                        "-" +
                                        selectedImage.name.toLowerCase().replace(/ /g, "");
                                    let fileName = await uploadAFile(name, selectedImage);
                                    if (fileName) {
                                        data.image = fileName;
                                        res = await updateACategory(data);
                                    }
                                }
                            }
                        }
                        if (res?.success) {
                            showToast(
                                `Category ${obj._id ? "updated" : "created"} successfully`,
                                "success"
                            );
                            onDialogClose(res.data);
                        } else {
                            showToast(
                                res?.message ||
                                `Error while ${values._id ? "updating" : "creating"
                                } category`,
                                "error"
                            );
                        }
                        setLoading(false);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit /* and other goodies */,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <CustomTextField name="title" label="Title" />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <CustomTextField name="slug" label="Slug" />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <CustomTextField multiline={true} rows={6} name="description" label="Description" />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {/* <CustomSwitch
                    name="isEnabled"
                    color="secondary"
                    label={values.isEnabled ? "Enabled" : "Disabled"}
                  /> */}
                                    <CustomSelect
                                        name="status"
                                        label="Status"
                                        menuList={Object.keys(STATUS_DICT).map((d) => {
                                            return { value: d, label: STATUS_DICT[d] };
                                        })}
                                    />
                                </Grid>
                                {/*<Grid item lg={4} md={4} sm={6} xs={12}>*/}
                                {/*    <CustomTextField name="deliveryTime" label="Delivery Time"/>*/}
                                {/*</Grid>*/}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <CustomSwitch name="isShowInAppDashBoard" label="Enable in HomeFeed" />
                                </Grid>
                                {/*<Grid item lg={4} md={4} sm={6} xs={12}>*/}
                                {/*    <CustomSwitch name="dontAllowToOrderWithOtherCat" label="Enable for same category order"/>*/}
                                {/*</Grid>*/}
                                {values.imageUrl ? (
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <GridList cellHeight={180} cols={1} className="gridList">
                                            <GridListTile>
                                                <img src={values.imageUrl} alt="log" />
                                                <GridListTileBar
                                                    actionIcon={
                                                        <IconButton
                                                            onClick={() =>
                                                                deleteImage(values.imageUrl as string, values)
                                                            }
                                                            color="secondary"
                                                        >
                                                            <Delete color="error" className="icon" />
                                                        </IconButton>
                                                    }
                                                />
                                            </GridListTile>
                                        </GridList>
                                    </Grid>
                                ) : (
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Image"
                                                type="file"
                                                name="image"
                                                onChange={(event: any) =>
                                                    handleFileChange(event.target.files)
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: {
                                                        accept: "image/x-png,image/gif,image/jpeg",
                                                    },
                                                }}
                                                error={errors.imageUrl && !selectedImage ? true : false}
                                                helperText={errors.imageUrl}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}



                                <Grid item xs={12}>
                                    <Grid container spacing={2} justify="flex-end">
                                        <Grid item>
                                            <Button
                                                onClick={() => onClose()}
                                                variant="outlined"
                                                color="secondary"
                                                type="button"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" type="submit">
                                                {values._id ? "Update" : "Add"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </section>
            {manageImageCropDialog.isOpen && (
                <ImageCropper
                    data={manageImageCropDialog.file}
                    onClose={handleCloseImageCropDialog}
                    crop={manageImageCropDialog.crop}
                />
            )}
        </React.Fragment>
    );
};

export default CategoryForm;
