import React from "react";
import ProductMain from "../../components/products/ProductMain";

const ProductPage: React.FC = () => {
  return (
    <section>
      <ProductMain />
    </section>
  );
};

export default ProductPage;
