import { httpClient } from "./UtilServices";
import {
  IStandardAPIResponse,
  ILocationGroup,
  ILocationGroupSearchObj,
} from "../vm";

export const addALocationGroup = async (
  data: ILocationGroup
): Promise<IStandardAPIResponse<ILocationGroup>> => {
  try {
    let res = await httpClient<ILocationGroup>(`location-groups`, "PUT", data);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateALocationGroup = async (
  data: ILocationGroup
): Promise<IStandardAPIResponse<ILocationGroup>> => {
  try {
    let res = await httpClient<ILocationGroup>(
      `location-groups/${data._id}`,
      "PATCH",
      data
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const deleteALocationGroup = async (
  id: string
): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient(`location-groups/${id}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getLocationGroups = async (
  searchObj: ILocationGroupSearchObj
): Promise<IStandardAPIResponse<Array<ILocationGroup>>> => {
  try {
    let res = await httpClient<Array<ILocationGroup>>(
      `location-groups`,
      "GET",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};
