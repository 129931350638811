import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, IUser, IUserSearchObj } from "../vm";

export const addUser = async (
  user: IUser
): Promise<IStandardAPIResponse<IUser>> => {
  try {
    let res = await httpClient<IUser>(`users`, "PUT", user);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateUser = async (
  user: IUser
): Promise<IStandardAPIResponse<IUser>> => {
  try {
    let res = await httpClient<IUser>(`users/${user._id}`, "PATCH", user);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const deleteUser = async (
  userId: string
): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient(`users/${userId}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getUsers = async (
  searchObj: IUserSearchObj
): Promise<IStandardAPIResponse<Array<IUser>>> => {
  try {
    let res = await httpClient<Array<IUser>>(`users`, "GET", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};
