import * as React from "react";
import DashboardMain from "../../components/dashboard/DashboardMain";

export interface DashMainPageProps {}

const DashMainPage: React.FC<DashMainPageProps> = () => {
  return (
    <section>
      <DashboardMain />
    </section>
  );
};

export default DashMainPage;
