import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import React from "react";
import { DASHBOARD_STATUS_LABEL, ORDER_STATUS } from "../../Constant";
import DashboardCard from "./DashboardCard";

export interface CommonDashboardProps {
  data: Array<{ _id: number; count: number }>;
  title: string;
  isOrder: boolean;
  isProduct: boolean;
}

const CommonDashboard: React.FC<CommonDashboardProps> = ({
  data,
  title,
  isOrder,
  isProduct,
}) => {
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={1}
          // className="dash-border"
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6" className="dashboard-title">
                {title}
              </Typography>
            </Grid>
            {/* <Divider /> */}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                {data
                  //   .sort((a, b) => b._id - a._id)
                  .map((d, index) => {
                    return !isOrder ? (
                      isProduct ? (
                        <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
                          <DashboardCard
                            title={DASHBOARD_STATUS_LABEL[d._id]}
                            count={d.count}
                            // icon={<AccountGroup />}
                            color="white"
                            secondaryColor="#ff5722"
                            url={`/products?status=${d._id}`}
                          />
                        </Grid>
                      ) : (
                        <Grid item lg={4} md={4} sm={4} xs={12} key={index}>
                          <DashboardCard
                            title={DASHBOARD_STATUS_LABEL[d._id]}
                            count={d.count}
                            // icon={<AccountGroup />}
                            color="white"
                            secondaryColor="#ffcd38"
                            url={`/categories?status=${d._id}`}
                          />
                        </Grid>
                      )
                    ) : (
                      <Grid item lg={2} md={2} sm={4} xs={12} key={index}>
                        <DashboardCard
                          title={ORDER_STATUS[d._id]}
                          count={d.count}
                          // icon={<AccountGroup />}
                          color="white"
                          secondaryColor="#35baf6"
                          url={`/orders?status=${d._id}`}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default CommonDashboard;
