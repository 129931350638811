import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, ICategory, ICategorySearchObj } from "../vm";

export const addACategory = async (
  category: ICategory
): Promise<IStandardAPIResponse<ICategory>> => {
  try {
    let res = await httpClient<ICategory>(`categories`, "PUT", category);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateACategory = async (
  category: ICategory
): Promise<IStandardAPIResponse<ICategory>> => {
  try {
    let res = await httpClient<ICategory>(
      `categories/${category._id}`,
      "PATCH",
      category
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const deleteACategory = async (
  categoryId: string
): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient(`categories/${categoryId}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getCategories = async (
  searchObj: ICategorySearchObj
): Promise<IStandardAPIResponse<Array<ICategory>>> => {
  try {
    let res = await httpClient<Array<ICategory>>(
      `categories`,
      "GET",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};
