import React from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Tooltip,
  IconButton,
  Chip,
} from "@material-ui/core";
import {
  getUserAddresses,
  deleteUserAddress,
} from "../../../services/AddressService";
import { IUserAddress } from "../../../vm";
import { ConfirmDialogContext } from "../../common/ConfirmDialogProvider";
import Loading from "../../common/Loading";
import { ToastContext } from "../../common/ToastProvider";
import { Pencil, TrashCan } from "mdi-material-ui";
import ManageUserAddressDialog from "./ManageUserAddressDialog";
import NoData from "../../common/NoData";

export interface UserAddressDetailsProps {
  userId: string;
}

const UserAddressDetails: React.FC<UserAddressDetailsProps> = ({ userId }) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [addresses, setAddresses] = React.useState([] as IUserAddress[]);
  const [manageAddressDialog, setManageAddressDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getAUserAddress();
    };
    asyncFunc();
  }, []);

  const getAUserAddress = async () => {
    setLoading(true);
    const result = await getUserAddresses({ userId: userId });
    if (result && result.success) {
      setAddresses(result.data);
    } else {
      showToast(
        result?.message || "Error while getting user address details",
        "error"
      );
    }
    setLoading(false);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = addresses[editIndex];
    }
    setManageAddressDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleClose = (data?: IUserAddress) => {
    let addressList = [...addresses];
    if (data) {
      if (manageAddressDialog.editIndex !== -1) {
        addressList[manageAddressDialog.editIndex] = data;
      } else {
        addressList.unshift(data);
      }
    }
    setAddresses(addressList);
    setManageAddressDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const deleteAUserAddress = (index: number) => {
    showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
      let addressList = [...addresses];
      setLoading(true);
      let result = await deleteUserAddress(addressList[index]._id as string);
      setLoading(false);
      if (result.success) {
        showToast("User address deleted successfully", "success");
        addressList.splice(index, 1);
        setAddresses(addressList);
      } else {
        showToast(
          result?.message || "Error while deleting user address",
          "error"
        );
      }
    });
  };

  const getAddress = (data: IUserAddress) => {
    let address = "";
    if (data.landMark) {
      address = address + data.landMark + ", ";
    }
    if (data.area) {
      address = address + data.area;
    }
    if (data.addressLine1) {
      address = address + ", " + data.addressLine1;
    }
    if (data.landMark) {
      address = address + ", " + data.landMark;
    }
    return address;
  };

  const getNamePhone = (data: IUserAddress) => {
    let namePhone = "";
    if (data.name) {
      namePhone = namePhone + data.name + "      ";
    }
    if (data.phone) {
      namePhone = namePhone + data.phone;
    }
    if (data.alternatePhone) {
      namePhone = namePhone + ",  " + data.alternatePhone;
    }
    return namePhone;
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">User Address</Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddDialogOpen(true)}
              >
                Add Address
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {addresses.length > 0 ? (
              addresses.map((d, index) => (
                <Grid item xs={12}>
                  <Paper className="padding-16">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Chip
                              // icon={icon}
                              label={d.area?.toUpperCase()}
                            />
                          </Grid>
                          <Grid item>
                            <Grid container>
                              <Grid item>
                                <Tooltip title="Update Address">
                                  <IconButton
                                    size="medium"
                                    color="primary"
                                    onClick={() =>
                                      handleAddDialogOpen(true, index)
                                    }
                                  >
                                    <Pencil />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title="Delete Address">
                                  <IconButton
                                    size="medium"
                                    color="secondary"
                                    onClick={() => deleteAUserAddress(index)}
                                  >
                                    <TrashCan />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" className="fs-16">
                          {getNamePhone(d)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{getAddress(d)}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Typography>
                              {d.additionalInfo + ", " + d.landMark + "  -"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">
                              {d.pincode}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            ) : (
              // <Grid item xs={12}>
              //   <Paper className="padding-16">
              //     <Grid container justify="center" alignItems="center">
              //       <Grid item>
              //         <Typography>No Address Found</Typography>
              //       </Grid>
              //     </Grid>
              //   </Paper>
              // </Grid>
              <NoData msg="No Address Found" />
            )}
          </Grid>
        </Grid>
      </Grid>
      {manageAddressDialog.isOpen && (
        <ManageUserAddressDialog
          onClose={handleClose}
          data={manageAddressDialog.data}
          userId={userId}
        />
      )}
    </React.Fragment>
  );
};

export default UserAddressDetails;
