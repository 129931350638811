import React from "react";
import { getOrders } from "../../../services/OrderService";
import { IOrder } from "../../../vm";
import { ToastContext } from "../../common/ToastProvider";
import {
  Grid,
  Typography,
  Paper
} from "@material-ui/core";
import Loading from "../../common/Loading";
import NoData from "../../common/NoData";
import { ORDER_STATUS } from "../../../Constant";
import ManageOrderDialog from "./ManageOrderDialog";
import moment from "moment";
import CustomerDrawer from "../../common/CustomerDrawer";

export interface UserOrderHistoryProps {
  userId: string;
}

const UserOrderHistory: React.FC<UserOrderHistoryProps> = ({ userId }) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const [isLoading, setLoading] = React.useState(false as boolean);
  const [orders, setOrders] = React.useState([] as IOrder[]);
  const [manageOrderDialog, setManageOrderDialog] = React.useState({
    isOpen: false,
    data: {},
    index: -1,
  } as {
    isOpen: boolean;
    data: any;
    index: number;
  });

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getMyOrders();
    };
    asyncFunc();
  }, []);

  const getMyOrders = async () => {
    setLoading(true);
    const result = await getOrders({ userId: userId });
    if (result && result.success) {
      setOrders(result.data);
    } else {
      showToast(
        result?.message || "Error while getting user order details",
        "error"
      );
    }
    setLoading(false);
  };

  const handleAddDialogOpen = (isOpen: boolean, index?: number) => {
    let data: any = undefined;
    if (index !== undefined) {
      data = orders[index];
    }
    setManageOrderDialog({
      isOpen: isOpen,
      data: data,
      index: index !== undefined ? index : -1,
    });
  };

  const handleClose = (data?: IOrder) => {
    let orderList = [...orders];
    if (data) {
      if (manageOrderDialog.index !== -1) {
        orderList[manageOrderDialog.index] = data;
      } else {
        orderList.unshift(data);
      }
    }
    setOrders(orderList);
    setManageOrderDialog({
      isOpen: false,
      data: undefined,
      index: -1,
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">User Orders</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {orders.length > 0 ? (
              orders.map((d, index) => (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  onClick={() => handleAddDialogOpen(true, index)}
                  className="pointer"
                >
                  <Paper className="padding-16">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" className="fs-16">
                          {("Order ID - " + d.orderNo) as string}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" className="fs-16">
                          {"Order Date - " +
                            moment(d.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {"RS " +
                            (d.totalEffectivePrice + d.effectiveDeliveryCharge)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{d.totalItem + " Items"}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="button">
                          {ORDER_STATUS[d.status]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            ) : (
              <NoData msg="No Orders Found" />
            )}
          </Grid>
        </Grid>
      </Grid>
      {manageOrderDialog.isOpen && (
        <CustomerDrawer
          title={"Order Details"}
          handleOpen={true}
          // fullWidth={true}
          onClose={() => handleClose()}
        >
          <ManageOrderDialog orderId={manageOrderDialog.data._id as string} />
        </CustomerDrawer>
      )}
    </React.Fragment>
  );
};

export default UserOrderHistory;
