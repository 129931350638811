import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, IOrder, IOrderSearchObj } from "../vm";

export const getOrders = async (searchObj: {
  userId: string;
}): Promise<IStandardAPIResponse<Array<IOrder>>> => {
  try {
    let res = await httpClient<Array<IOrder>>(`my-orders`, "GET", searchObj);
    return res;
  } catch (err: any) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getOrderDetails = async (searchObj: {
  orderId: string;
}): Promise<IStandardAPIResponse<IOrder>> => {
  try {
    let res = await httpClient<IOrder>(
      `order-details/${searchObj.orderId}`,
      "GET"
    );
    return res;
  } catch (err: any) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getAllOrders = async (
  searchObj: IOrderSearchObj
): Promise<IStandardAPIResponse<Array<IOrder>>> => {
  try {
    let res = await httpClient<Array<IOrder>>(`orders`, "GET", searchObj);
    return res;
  } catch (err: any) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const updateAOrderDetails = async (
  data: IOrder
): Promise<IStandardAPIResponse<IOrder>> => {
  try {
    let res = await httpClient<IOrder>(`orders/${data._id}`, "PATCH", data);
    return res;
  } catch (err: any) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const rejectAOrder = async (
  data: IOrder
): Promise<IStandardAPIResponse<IOrder>> => {
  try {
    let res = await httpClient<IOrder>(
      `orders/${data._id}/reject-cancel-by-admin`,
      "POST",
      data
    );
    return res;
  } catch (err: any) {
    return err && err.response ? err.response.data : undefined;
  }
};
