import React from "react";
import { IOrder, ISelectMenuList } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { Formik } from "formik";
import { Grid, Button } from "@material-ui/core";
import { Loading } from "../common/Loading";
import * as yup from "yup";
import { CustomSelect, CustomTextField } from "../common/Library";
import { ORDER_STATUS } from "../../Constant";
import { removeNulls } from "../../services/UtilServices";
import { updateAOrderDetails } from "../../services/OrderService";

const assignDeliveryBoySchema = yup.object().shape({
  deliveredBy: yup.string().required("required"),
});

export interface AssignDeliveryBoyFormProps {
  onClose: Function;
  data?: IOrder;
  deliveryBoyDict: any;
}

const AssignDeliveryBoyForm: React.FC<AssignDeliveryBoyFormProps> = ({
  onClose,
  data,
  deliveryBoyDict,
}) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const [isLoading, setLoading] = React.useState(false as boolean);
  const [dataObj, setDataObj] = React.useState({
    deliveredBy: "",
    remarks: "",
    ...data,
  } as IOrder);

  const onDialogClose = (data?: IOrder) => {
    onClose(data);
  };

  const statusList: ISelectMenuList[] = [];
  Object.keys(ORDER_STATUS).forEach((d: any) => {
    if (["2", "7", `${data?.status as number}`].includes(d)) {
      statusList.push({ value: d, label: ORDER_STATUS[d] });
    }
  });



  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="padding-16">
        <Formik
          enableReinitialize
          initialValues={dataObj}
          validationSchema={assignDeliveryBoySchema}
          onSubmit={async (values: IOrder, { setSubmitting }) => {
            setLoading(true);
            let obj = { ...values };
            obj = removeNulls(obj);
            obj.status = Number(obj.status);
            const res = await updateAOrderDetails(obj);
            setLoading(false);
            if (res?.success) {
              showToast(`Assign delivery boy successfully`, "success");
              onDialogClose(obj);
            } else {
              showToast(
                res?.message || `Error while assigning delivery boy`,
                "error"
              );
            }
            setSubmitting(false);
          }}
        >
          {({ handleSubmit /* and other goodies */ }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomSelect
                    name="deliveredBy"
                    label="Delivery Boy"
                    menuList={Object.keys(deliveryBoyDict).map((d) => {
                      return { value: d, label: deliveryBoyDict[d] };
                    })}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomSelect
                    name="status"
                    label="Status"
                    menuList={statusList}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField name="remarks" label="Remarks" />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => onClose()}
                        variant="outlined"
                        color="secondary"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        {"Update"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </section>
    </React.Fragment>
  );
};

export default AssignDeliveryBoyForm;
