import React from "react";
import {
  ILocationGroup,
  IProduct,
  ISelectMenuList,
  ISubCategory,
} from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { Formik } from "formik";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
} from "@material-ui/core";
import {
  debounce,
  getFileByBase64,
  getSignedUrl,
  uploadFile,
} from "../../services/UtilServices";
import { Loading } from "../common/Loading";
import { addAProduct, updateAProduct } from "../../services/ProductService";
import * as yup from "yup";
import {
  CustomSelect,
  CustomSwitch,
  CustomTextField,
  ReactSelect,
} from "../common/Library";
import ImageCropper from "../common/ImageCropper";
import { Delete } from "mdi-material-ui";
import {
  PRODUCT_UNIT,
  STATUS_DICT,
  STATUS_DICT_FOR_PRODUCT,
} from "../../Constant";


const productSchema = yup.object().shape({
  title: yup.string().required("required"),
  slug: yup.string().required("required"),
  subCategoryIds: yup.array().min(1),
  categoryIds: yup.array().min(1),
  locationGroupIds: yup.array().min(1),
  // imageUrl: yup.string().required("required"),
});

const productWithOptionSchema = productSchema.shape({
  mrp: yup.number().required("required"),
  sellingPrice: yup.number().required("required"),
  unitLabel: yup.string().required("required"),
});

export interface ProductFormProps {
  onClose: Function;
  data?: IProduct;
  subCategories: ISubCategory[];
  categoryOptions: ISelectMenuList[];
  locationGroupNames: ILocationGroup[];
}

const ProductForm: React.FC<ProductFormProps> = ({
  onClose,
  data,
  subCategories,
  locationGroupNames,
  categoryOptions,
}) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const [isLoading, setLoading] = React.useState(false as boolean);
  const [product, setProduct] = React.useState({
    subCategoryIds: [],
    categoryIds: [],
    title: "",
    slug: "",
    image: "",
    imageUrl: "",
    // isEnabled: false,
    isDeleted: false,
    deletedImage: "",
    mrp: 0,
    priceUnit: "piece",
    unitLabel: "",
    unitLabel2: "",
    sellingPrice: 0,
    locationGroupIds: [],
    status: 0,
    deliveryTime: 0,
    veg: false,
    ...data,
  } as IProduct);
  const [selectedImage, setSelectedImage] = React.useState(undefined as any);
  const [manageImageCropDialog, setManageImageCropDialog] = React.useState({
    isOpen: false,
    file: undefined,
    crop: {
      unit: "%",
      width: 60,
      aspect: 4 / 4,
    },
  } as {
    isOpen: boolean;
    file?: any;
    crop?: any;
  });
  const [selectedItems, setSelectedItems] = React.useState(
    [] as ISelectMenuList[]
  );

  const onDialogClose = (data?: IProduct) => {
    onClose(data);
  };

  const handleFileChange = (files: any) => {
    if (files[0].type.includes("image/")) {
      setSelectedImage(files[0]);
      handleOpenImageCropDialog(files[0]);
    } else {
      showToast("Select only images", "error");
    }
  };

  const uploadAFile = async (fileName: any, file: any) => {
    setLoading(true);
    try {
      console.log("uploading file", fileName);
      let result = await getSignedUrl([fileName], file.type);
      console.log("s3 url", fileName, result.data[0]);
      if (result.success) {
        await uploadFile(result.data[0], file);
        return fileName;
      } else {
        showToast(`Error while uploading image`, "error");
      }
    } catch (error) {
      //@ts-ignore
      showToast(`Error while uploading image: ${error.message}`, "error");
    }
    return undefined;
  };

  const handleOpenImageCropDialog = (file: any) => {
    setManageImageCropDialog({ ...manageImageCropDialog, isOpen: true, file });
  };

  const handleCloseImageCropDialog = async (data?: any) => {
    let selectedImg = undefined;
    if (data) {
      selectedImg = await getFileByBase64(data, selectedImage.name);
    }
    setManageImageCropDialog({
      ...manageImageCropDialog,
      file: undefined,
      isOpen: false,
    });
    setSelectedImage(selectedImg);
  };

  const deleteImage = (deletedImage: string, values: any) => {
    setProduct({
      ...values,
      imageUrl: "",
      image: "",
      deletedImage: deletedImage,
    });
  };

  const onSubCategoryChange = (subCategoryIds: any, values: IProduct) => {
    setProduct({ ...values, subCategoryIds });
    // setSelectedItems(newValue ? newValue : []);
  };

  const onCategoryChange = (categoryIds: any, values: IProduct) => {
    const subCategoryIds = subCategories
      .filter((d) => categoryIds.includes(d.categoryId))
      .filter((d) => [...values.subCategoryIds].includes(d._id as string))
      .map((d) => {
        return d._id as string;
      });
    setProduct({ ...values, categoryIds, subCategoryIds });
  };

  const onLocationsGroupNameChange = (
    locationGroupIds: any,
    values: IProduct
  ) => {
    setProduct({ ...values, locationGroupIds });
  };
  const isEdit = data?._id != null;
  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="padding-16">
        <Formik
          enableReinitialize
          initialValues={product}
          validationSchema={isEdit ? productSchema : productWithOptionSchema}
          onSubmit={async (values: IProduct, { setSubmitting }) => {
            let res;
            let obj = { ...values };
            // obj = removeNulls(obj);
            setLoading(true);
            if (obj._id) {
              if (selectedImage) {
                let name =
                  `products/${obj._id}/` +
                  new Date().getTime() +
                  "-" +
                  selectedImage.name.toLowerCase().replace(/ /g, "");
                console.log("name", name);
                let fileName = await uploadAFile(name, selectedImage);
                if (fileName) {
                  obj.image = fileName;
                }
              }
              res = await updateAProduct(obj);
            } else {
              res = await addAProduct(obj);
              if (res?.success) {
                if (selectedImage) {
                  let data = { ...res.data };
                  let name =
                    `products/${res.data._id}/` +
                    new Date().getTime() +
                    "-" +
                    selectedImage.name.toLowerCase().replace(/ /g, "");
                  console.log("name 2", name);

                  let fileName = await uploadAFile(name, selectedImage);
                  if (fileName) {
                    data.image = fileName;
                    res = await updateAProduct(data);
                  }
                }
              }
            }
            if (res?.success) {
              showToast(
                `Product ${obj._id ? "updated" : "created"} successfully`,
                "success"
              );
              onDialogClose(res.data);
            } else {
              showToast(
                res?.message ||
                `Error while ${values._id ? "updating" : "creating"} product`,
                "error"
              );
            }
            setLoading(false);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit /* and other goodies */,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <CustomTextField name="title" label="Title" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <CustomTextField name="slug" label="Slug" />
                </Grid>
                {!isEdit && (
                  <>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomSelect
                        name="priceUnit"
                        label="Price Unit"
                        menuList={Object.keys(PRODUCT_UNIT).map((d) => {
                          return { value: d, label: PRODUCT_UNIT[d] };
                        })}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomTextField name="unitLabel" label="Unit Label" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomTextField name="mrp" label="MRP" type="number" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomTextField
                        name="sellingPrice"
                        label="Selling Price"
                        type="number"
                      />
                    </Grid>
                  </>
                )}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField
                    multiline={true}
                    rows={6}
                    name="description"
                    label="Description"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ReactSelect
                    value={values.categoryIds}
                    isClearable={true}
                    isMulti={true}
                    options={categoryOptions}
                    values={values}
                    onChange={onCategoryChange}
                    label={"Categories"}
                  />
                  {errors.categoryIds && touched.categoryIds && (
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                      {"select at least one"}
                    </p>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ReactSelect
                    value={values.subCategoryIds}
                    isClearable={true}
                    isMulti={true}
                    options={subCategories
                      .filter((d) => values.categoryIds.includes(d.categoryId))
                      .map((d) => {
                        return { value: d._id as string, label: d.title };
                      })}
                    values={values}
                    onChange={onSubCategoryChange}
                    label={"Sub-Categories"}
                  />
                  {errors.subCategoryIds && touched.subCategoryIds && (
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                      {"select at least one"}
                    </p>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ReactSelect
                    value={values.locationGroupIds}
                    isClearable={true}
                    isMulti={true}
                    options={locationGroupNames.map((d) => {
                      return { value: d._id as string, label: d.title };
                    })}
                    values={values}
                    onChange={onLocationsGroupNameChange}
                    label={"Locations Group"}
                  />
                  {errors.locationGroupIds && touched.locationGroupIds && (
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
                      {"select at least one"}
                    </p>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomSelect
                    name="status"
                    label="Status"
                    menuList={Object.keys(STATUS_DICT_FOR_PRODUCT).map((d) => {
                      return { value: d, label: STATUS_DICT_FOR_PRODUCT[d] };
                    })}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CustomTextField
                    name="deliveryTime"
                    label="Delivery Time (mins)"
                    type={"number"}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CustomSwitch name="veg" label="Is Veg" />
                </Grid>
                {values.imageUrl ? (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <GridList cellHeight={180} cols={1} className="gridList">
                      <GridListTile>
                        <img src={values.imageUrl} alt="log" />
                        <GridListTileBar
                          actionIcon={
                            <IconButton
                              onClick={() =>
                                deleteImage(values.imageUrl as string, values)
                              }
                              color="secondary"
                            >
                              <Delete color="error" className="icon" />
                            </IconButton>
                          }
                        />
                      </GridListTile>
                    </GridList>
                  </Grid>
                ) : (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Base Image"
                        type="file"
                        name="image"
                        onChange={(event: any) =>
                          handleFileChange(event.target.files)
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            accept: "image/x-png,image/gif,image/jpeg",
                          },
                        }}
                        error={errors.imageUrl && !selectedImage ? true : false}
                        helperText={errors.imageUrl}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => onClose()}
                        variant="outlined"
                        color="secondary"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        {values._id ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </section>
      {manageImageCropDialog.isOpen && (
        <ImageCropper
          data={manageImageCropDialog.file}
          onClose={handleCloseImageCropDialog}
          crop={manageImageCropDialog.crop}
        />
      )}
    </React.Fragment>
  );
};

export default ProductForm;
