import React from "react";
import CustomDrawer from "../../common/CustomerDrawer";
import { IUserAddress } from "../../../vm";
import { ToastContext } from "../../common/ToastProvider";
import { Formik, FormikErrors } from "formik";
import {
  updateUserAddress,
  addUserAddress,
} from "../../../services/AddressService";
import {
  Grid,
  Button,
} from "@material-ui/core";
import {
  removeNulls,
} from "../../../services/UtilServices";
import { Loading } from "../../common/Loading";
import { CustomTextField } from "../../common/Library";

// const coordinatesLabelDict: any = {
//   0: "Longitude",
//   1: "Latitude",
// };
export interface IManageUserAddressDialogProps {
  onClose: Function;
  data?: IUserAddress;
  userId: string;
}

const ManageUserAddressDialog: React.FC<IManageUserAddressDialogProps> = ({
  data,
  onClose,
  userId,
}) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [address, setAddress] = React.useState({
    userId: userId,
    name: "",
    phone: "",
    pincode: "",
    locality: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    landMark: "",
    alternatePhone: "",
    addressType: "home",
    isDeleted: false,
    location: {
      type: "Point",
      coordinates: [0, 0],
    },
    ...data,
  } as IUserAddress);

  const onDialogClose = (data?: IUserAddress) => {
    onClose(data);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer
        title={address._id ? "Edit Address" : "Add Address"}
        handleOpen={true}
        onClose={() => onDialogClose()}
      >
        <section className="padding-16">
          <Formik
            enableReinitialize
            initialValues={address}
            validate={(values) => {
              let errors: FormikErrors<IUserAddress> = {};
              if (!values.pincode) {
                errors.pincode = "Required";
              } else if (String(values.pincode).length !== 6) {
                errors.pincode = "Please enter valid pincode";
              }
              if (!values.area) {
                errors.area = "Required";
              }
              if (!values.addressLine1) {
                errors.addressLine1 = "Required";
              }
              if (!values.phone) {
                errors.phone = "Required";
              } else if (String(values.phone).length !== 10) {
                errors.phone = "Please enter valid phone";
              }
              if (!values.city) {
                errors.city = "Required";
              }
              if (!values.state) {
                errors.state = "Required";
              }
              // if (values && values.location &&
              //   values.location.coordinates &&
              //   values.location.coordinates.length < 2
              // ) {
              //   errors.location.coordinates = "Required";
              // }
              return errors;
            }}
            onSubmit={async (values: IUserAddress, { setSubmitting }) => {
              setLoading(true);
              let res;
              let obj = { ...values };
              obj = removeNulls(obj);
              obj.pincode = String(obj.pincode);
              // obj.location.coordinates = obj.location.coordinates.map((d) =>
              //   Number(d)
              // );
              if (values._id) {
                res = await updateUserAddress(obj);
              } else {
                res = await addUserAddress(obj);
              }
              setLoading(false);
              if (res?.success) {
                showToast(
                  `Address ${values._id ? "updated" : "created"} successfully`,
                  "success"
                );
                onDialogClose(values._id ? obj : res.data);
              } else {
                showToast(
                  res?.message ||
                  `Error while ${values._id ? "updating" : "creating"
                  } address`,
                  "error"
                );
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit /* and other goodies */,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextField label="Name" name="name" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextField label="Mobile No" name="phone" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextField
                      label="Land Mark"
                      type="text"
                      name="landMark"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextField label="Village" name="village" />
                  </Grid>
                  {/* {values.location.coordinates.map((d, index) => (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomTextField
                        label={coordinatesLabelDict[index]}
                        name={`location.coordinates[${index}]`}
                        type="number"
                      />
                    </Grid>
                  ))} */}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomTextField
                      label="Address Line 1"
                      name="additionalInfo"
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomTextField
                      label="Address Line 2"
                      name="addressLine2"
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextField label="City" name="city" />
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextField label="State" name="state" />
                  </Grid> */}
                  <Grid item lg={6} md={4} sm={12} xs={12}>
                    <CustomTextField label="Pin Code" name="pincode" />
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                    <CustomTextField
                      label="Alternate Mobile No"
                      name="alternatePhone"
                    />
                  </Grid> */}
                  {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                    <CustomSelect
                      name="addressType"
                      label="Address Type"
                      menuList={Object.keys(ADDRESS_TYPE).map((d) => {
                        return { value: d, label: ADDRESS_TYPE[d] };
                      })}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                      <Grid item>
                        <Button
                          onClick={() => onClose()}
                          variant="outlined"
                          color="secondary"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {values._id ? "Update" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ManageUserAddressDialog;
