import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, IProduct, IProductSearchObj, IProductOptions } from "../vm";

export const addAProduct = async (
    product: IProduct
): Promise<IStandardAPIResponse<IProduct>> => {
    try {
        let res = await httpClient<IProduct>(`products`, "PUT", product);
        return res;
    } catch (err) {
        return err;
    }
};

export const updateAProduct = async (
    product: IProduct
): Promise<IStandardAPIResponse<IProduct>> => {
    try {
        console.log("product", product);
        let res = await httpClient<IProduct>(
            `products/${product._id}`,
            "PATCH",
            product
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};

export const deleteAProduct = async (
    productId: string
): Promise<IStandardAPIResponse<any>> => {
    try {
        let res = await httpClient(`products/${productId}`, "DELETE");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};

export const getProducts = async (
    searchObj: IProductSearchObj
): Promise<IStandardAPIResponse<Array<IProduct>>> => {
    try {
        let res = await httpClient<Array<IProduct>>(`products`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};

export const getAProductOptions = async (
    productId: string
): Promise<IStandardAPIResponse<Array<IProductOptions>>> => {
    try {
        let res = await httpClient<Array<IProductOptions>>(`products/${productId}/options`, "GET");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};

export const addAProductOptions = async (
    productId: string,
    obj: IProductOptions
): Promise<IStandardAPIResponse<IProductOptions>> => {
    try {
        let res = await httpClient<IProductOptions>(`products/${productId}/options`, "PUT", obj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};

export const updateProductOptions = async (
    id: string,
    productId: string,
    obj: IProductOptions
): Promise<IStandardAPIResponse<IProductOptions>> => {
    try {
        let res = await httpClient<IProductOptions>(`products/${productId}/options/${id}`, "PATCH", obj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};
export const removeAProductOptions = async (
    id: string,
    productId: string
): Promise<IStandardAPIResponse<IProductOptions>> => {
    try {
        let res = await httpClient<IProductOptions>(`products/${productId}/options/${id}`, "DELETE");
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};
