import React from "react";
import OrdersMain from "../../components/orders/OrdersMain";

const OrdersPage: React.FC = () => {
  return (
    <section>
      <OrdersMain />
    </section>
  );
};

export default OrdersPage;
