import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, IUserAddress } from "../vm";

export const addUserAddress = async (
  userAddress: IUserAddress
): Promise<IStandardAPIResponse<IUserAddress>> => {
  try {
    let res = await httpClient<IUserAddress>(
      `user-address`,
      "PUT",
      userAddress
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const updateUserAddress = async (
  userAddress: IUserAddress
): Promise<IStandardAPIResponse<IUserAddress>> => {
  try {
    let res = await httpClient<IUserAddress>(
      `user-address/${userAddress._id}`,
      "PATCH",
      userAddress
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const deleteUserAddress = async (
  userAddressId: string
): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient(`user-address/${userAddressId}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getUserAddresses = async (searchObj: {
  userId: string;
}): Promise<IStandardAPIResponse<Array<IUserAddress>>> => {
  try {
    let res = await httpClient<Array<IUserAddress>>(
      `user-address`,
      "GET",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};
