import React from 'react';
import Routes from './Routes';
import { ConfirmDialogProvider } from './components/common/ConfirmDialogProvider';
import { ToastProvider } from './components/common/ToastProvider';
import { ThemeProvider } from '@material-ui/core';
import { THEME } from './Styles';

const App: React.FC = () => {
  return (
      <React.Fragment>
        <ThemeProvider theme={THEME}>
          <ConfirmDialogProvider>
            <ToastProvider>
              <Routes />
            </ToastProvider>
          </ConfirmDialogProvider>
        </ThemeProvider>
      </React.Fragment>
  );
};

export default App;
