import * as React from "react";
import { IUser, IUserSearchObj } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { getUsers, deleteUser } from "../../services/UsersService";
import { ConfirmDialogContext } from "../common/ConfirmDialogProvider";
import {
  Grid,
  Typography,
  Button,
  Fab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TableContainer,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  OpenInNew,
  Pencil,
  TrashCan,
} from "mdi-material-ui";
import ManageUserDialog from "./ManageUserDialog";
// import { parseJwt } from "../../services/UtilServices";
import { TIME_PERIOD, USER_ROLES } from "../../Constant";
import Loading from "../common/Loading";
import { getFullName } from "../../services/UtilServices";
import NoData from "../common/NoData";
import moment from "moment";

export interface UserMainProps { }

const USER_ROLES_WITH_ALL: any = { 99: "All", ...USER_ROLES };

const UserMain: React.FC<UserMainProps> = () => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
  //const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [users, setUsers] = React.useState([] as IUser[]);
  const [hasMoreUsers, setHasMoreUsers] = React.useState(false as boolean);
  const [manageUserDialog, setManageUserDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });
  const [searchObj, setSearchObj] = React.useState({
    offset: 0,
    limit: 10,
    // name: "",
    roleId: queryParams.get("roleId") ? Number(queryParams.get("roleId")) : 99,
    // email: "",
    search: "",
    // userId: "",
    // isEnabled: "all",
    timePeriod: queryParams.get("timePeriod")
      ? queryParams.get("timePeriod")
      : "all",
    startDate: queryParams.get("startDate")
      ? queryParams.get("startDate")
      : undefined,
    endDate: queryParams.get("endDate")
      ? queryParams.get("endDate")
      : undefined,
  } as IUserSearchObj);

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getUsersList();
    };
    asyncFunc();
  }, []);

  const getUsersList = async (search?: any) => {
    setLoading(true);
    let serchObj = { ...searchObj };
    if (search != null) {
      serchObj = { ...serchObj, ...search };
    }
    if (Number(serchObj.roleId) === 99) {
      delete serchObj.roleId;
    } else {
      serchObj.roleId = Number(serchObj.roleId);
    }
    // manage date period
    switch (serchObj.timePeriod) {
      case "today":
        serchObj.startDate = moment().format("MM/DD/YYYY");
        serchObj.endDate = moment().format("MM/DD/YYYY");
        break;
      case "this-week":
        serchObj.startDate = moment().startOf("week").format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("week").format("MM/DD/YYYY");
        break;
      case "this-month":
        serchObj.startDate = moment().startOf("month").format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "last-three-month":
        serchObj.startDate = moment()
          .subtract(2, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "last-nine-month":
        serchObj.startDate = moment()
          .subtract(8, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "custom-date":
        serchObj.startDate = moment(serchObj.startDate).format("MM/DD/YYYY");
        serchObj.endDate = moment(serchObj.endDate).format("MM/DD/YYYY");
        break;
      default:
        delete serchObj.startDate;
        delete serchObj.endDate;
        break;
    }
    const result = await getUsers(serchObj);
    // replace url to original url
    if (queryParams.get("roleId")) {
      window.history.replaceState(null, "", "/users");
    }
    if (serchObj.roleId == null) {
      serchObj.roleId = 99;
    }
    if (result && result.success) {
      setUsers(result.data);
      setSearchObj(serchObj);
      setHasMoreUsers(result.data.length === searchObj.limit);
    } else {
      showToast(result?.message || "Error while getting users list", "error");
    }
    setLoading(false);
  };

  const getUsersFrom = async (offset: number) => {
    let search = { ...searchObj };
    search.offset = offset;
    await getUsersList(search);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = users[editIndex];
    }
    setManageUserDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleClose = (data?: IUser) => {
    let usersList = [...users];
    if (data) {
      if (manageUserDialog.editIndex !== -1) {
        usersList[manageUserDialog.editIndex] = data;
      } else {
        usersList.unshift(data);
      }
    }
    setUsers(usersList);
    setManageUserDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const deleteAUser = (index: number) => {
    showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
      let usersList = [...users];
      setLoading(true);
      let result = await deleteUser(usersList[index]._id as string);
      setLoading(false);
      if (result.success) {
        showToast("User deleted successfully", "success");
        usersList.splice(index, 1);
        setUsers(usersList);
      } else {
        showToast(result?.message || "Error while deleting user", "error");
      }
    });
  };

  const viewUserProfile = (userId: string) => {
    // history.push(`/users/${userId}/profile`);
    window.open(`/users/${userId}/profile`);
  };

  const handleChange = (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    if (name === "timePeriod" && value === "custom-date") {
      setSearchObj({
        ...searchObj,
        timePeriod: value,
        startDate: undefined,
        endDate: undefined,
      });
    } else {
      setSearchObj({
        ...searchObj,
        [name]: value,
      });
    }
  };

  const onSearch = async () => {
    let serchObj = { ...searchObj };
    serchObj.offset = 0;
    await getUsersList(serchObj);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Users</Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddDialogOpen(true)}
              >
                Add User
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className="padding-16">
            <Grid
              container
              spacing={2}
              alignItems="center"
            // justify="space-around"
            >
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="search"
                  label="Search by name / email"
                  value={searchObj.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                item
                lg={searchObj.timePeriod === "custom-date" ? 4 : 3}
                md={6}
                sm={6}
                xs={12}
              >
                <FormControl fullWidth>
                  <InputLabel id="filterByRole" className="select-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="filterByRole"
                    value={searchObj.roleId}
                    name="roleId"
                    onChange={handleChange}
                  >
                    {Object.keys(USER_ROLES_WITH_ALL).map((index) => (
                      <MenuItem value={index} key={index}>
                        {USER_ROLES_WITH_ALL[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={searchObj.timePeriod === "custom-date" ? 4 : 2}
                md={searchObj.timePeriod === "custom-date" ? 3 : 6}
                sm={6}
                xs={12}
              >
                <FormControl fullWidth>
                  <InputLabel id="timePeriod" className="select-label">
                    Time Period
                  </InputLabel>
                  <Select
                    labelId="timePeriod"
                    value={searchObj.timePeriod}
                    name="timePeriod"
                    onChange={handleChange}
                  >
                    {Object.keys(TIME_PERIOD).map((index) => (
                      <MenuItem value={index} key={index}>
                        {TIME_PERIOD[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {searchObj.timePeriod === "custom-date" && (
                <>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="startDate"
                      type="date"
                      label="Start Date"
                      value={moment(searchObj.startDate).format("YYYY-MM-DD")}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="endDate"
                      type="date"
                      label="End Date"
                      value={moment(searchObj.endDate).format("YYYY-MM-DD")}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              <Grid item>
                <Button variant="contained" color="primary" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    const searchObj = {
                      offset: 0,
                      limit: 10,
                      // name: "",
                      roleId: 99,
                      // email: "",
                      search: "",
                      // userId: "",
                      // isEnabled: "all",
                      timePeriod: "all",
                      startDate: undefined,
                      endDate: undefined,
                    };
                    await getUsersList(searchObj);
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Role</TableCell>
                  {/* <TableCell>Gender</TableCell> */}
                  {/* <TableCell>DOB</TableCell> */}
                  <TableCell>Is Enabled</TableCell>
                  {/* <TableCell>Is Deleted</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((user, userIndex) => (
                    <TableRow key={user._id}>
                      <TableCell>
                        <strong>
                          <Typography>{getFullName(user)}</Typography>
                        </strong>
                      </TableCell>
                      <TableCell>
                        <Typography>{user.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{user.phone}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{USER_ROLES[user.roleId]}</Typography>
                      </TableCell>
                      {/* <TableCell>
                        <Typography>{user.gender}</Typography>
                      </TableCell> */}
                      {/* <TableCell>
                        <Typography>{user.dob}</Typography>
                      </TableCell> */}
                      <TableCell>
                        <Typography>{user.isEnabled ? "YES" : "NO"}</Typography>
                      </TableCell>
                      {/* <TableCell>
                        <Typography>{user.isDeleted ? "YES" : "NO"}</Typography>
                      </TableCell> */}
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            <Tooltip title="Update User">
                              <IconButton
                                size="medium"
                                color="primary"
                                onClick={() =>
                                  handleAddDialogOpen(true, userIndex)
                                }
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Delete User">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() => deleteAUser(userIndex)}
                              >
                                <TrashCan />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="View Profile">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() =>
                                  viewUserProfile(user._id as string)
                                }
                              >
                                <OpenInNew />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <NoData msg="No Users found" isTable={true} />
                      {/* <br />
                      <Typography variant="subtitle1">
                        No Users found
                      </Typography>
                      <br /> */}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2} justify="flex-end" className="padding-16">
            {(searchObj?.offset || 0) > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getUsersFrom(
                      (searchObj?.offset || 0) - (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {hasMoreUsers && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getUsersFrom(
                      (searchObj?.offset || 0) + (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {manageUserDialog.isOpen && (
        <ManageUserDialog onClose={handleClose} data={manageUserDialog.data} />
      )}
    </React.Fragment>
  );
};

export default UserMain;
