import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, IBannerSearchObj, IShop } from "../vm";



export const addAShop = async (
    shop: IShop
): Promise<IStandardAPIResponse<IShop>> => {
    try {
        let res = await httpClient<IShop>(`shops`, "PUT", shop);
        return res;
    } catch (err) {
        return err;
    }
};

export const getShops = async (
    searchObj: IBannerSearchObj
): Promise<IStandardAPIResponse<Array<IShop>>> => {
    try {
        let res = await httpClient<Array<IShop>>(`shops`, "POST", searchObj);
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};

export const updateAShop = async (
    shop: IShop
): Promise<IStandardAPIResponse<IShop>> => {
    try {
        let res = await httpClient<IShop>(
            `shops/${shop._id}`,
            "PATCH",
            shop
        );
        return res;
    } catch (err) {
        return err && err.response ? err.response.data : undefined;
    }
};