import React from "react";
import { IOrder, IOrderSearchObj } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { ConfirmDialogContext } from "../common/ConfirmDialogProvider";
import {
  Grid,
  Typography,
  Button,
  Fab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TableContainer,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  Pencil,
  Eye,
  AccountPlus,
  CheckDecagram,
  CloseOctagon,
  AlertDecagram,
} from "mdi-material-ui";
import {
  PAYMENT_METHOD,
  ORDER_STATUS,
  DELIVERY_STATUS,
  ORDER_APPROVAL_STATUS,
  TIME_PERIOD,
} from "../../Constant";
import Loading from "../common/Loading";
import { getFullName } from "../../services/UtilServices";
import { useHistory } from "react-router-dom";
import NoData from "../common/NoData";
import {
  getAllOrders,
  rejectAOrder,
  updateAOrderDetails,
} from "../../services/OrderService";
import moment from "moment";
import CustomDialog from "../../components/common/CustomDialog";
import ManageOrderDialog from "../users/user-order-history/ManageOrderDialog";
import CustomerDrawer from "../common/CustomerDrawer";
import { getUsers } from "../../services/UsersService";
import AssignDeliveryBoyForm from "./AssignDeliveryBoyForm";

export interface IOrdersMainProps { }

const PAYMENT_METHOD_WITH_ALL: any = { all: "All", ...PAYMENT_METHOD };
const ORDER_STATUS_WITH_ALL: any = { 99: "All", ...ORDER_STATUS };
const DELIVERY_STATUS_WITH_ALL: any = { 99: "All", ...DELIVERY_STATUS };

const OrdersMain: React.FC<IOrdersMainProps> = () => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
  const queryParams = new URLSearchParams(window.location.search);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [orders, setOrders] = React.useState([] as IOrder[]);
  const [hasMoreOrders, setHasMoreOrders] = React.useState(false as boolean);
  const [searchObj, setSearchObj] = React.useState({
    offset: 0,
    limit: 10,
    search: "",
    userName: "",
    status: queryParams.get("status") ? Number(queryParams.get("status")) : 99,
    paymentMethod: queryParams.get("paymentMethod")
      ? queryParams.get("paymentMethod")
      : "all",
    deliveryVerificationStatus: 99,
    deliveredBy: "",
    timePeriod: queryParams.get("timePeriod")
      ? queryParams.get("timePeriod")
      : "all",
    startDate: queryParams.get("startDate")
      ? queryParams.get("startDate")
      : undefined,
    endDate: queryParams.get("endDate")
      ? queryParams.get("endDate")
      : undefined,
  } as IOrderSearchObj);
  const [manageDialog, setManageDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });
  const [manageApproveDialog, setManageApproveDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
    status: 5,
    remarks: "",
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
    status: number;
    remarks: string;
  });
  const [manageOrderDetailsDialog, setManageOrderDetailsDialog] =
    React.useState({
      isOpen: false,
      id: undefined,
    } as {
      isOpen: boolean;
      id?: string;
    });
  const [deliveryBoyDict, setDeliveryBoyDict] = React.useState({} as any);

  React.useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true);
      await getDeliveryBoy();
      await getOrdersList();
    };
    asyncFunc();
  }, []);

  const getOrdersList = async (search?: any) => {
    setLoading(true);
    let serchObj = { ...searchObj };
    if (search != null) {
      serchObj = { ...serchObj, ...search };
    }

    if (Number(serchObj.status) === 99) {
      delete serchObj.status;
    } else {
      serchObj.status = Number(serchObj.status);
    }
    if (Number(serchObj.deliveryVerificationStatus) === 99) {
      delete serchObj.deliveryVerificationStatus;
    } else {
      serchObj.deliveryVerificationStatus = Number(
        serchObj.deliveryVerificationStatus
      );
    }
    if (serchObj.paymentMethod === "all") {
      delete serchObj.paymentMethod;
    } else {
      serchObj.paymentMethod = serchObj.paymentMethod;
    }
    // manage date period
    switch (serchObj.timePeriod) {
      case "today":
        serchObj.startDate = moment().format("MM/DD/YYYY");
        serchObj.endDate = moment().format("MM/DD/YYYY");
        break;
      case "this-week":
        serchObj.startDate = moment().startOf("week").format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("week").format("MM/DD/YYYY");
        break;
      case "this-month":
        serchObj.startDate = moment().startOf("month").format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "last-three-month":
        serchObj.startDate = moment()
          .subtract(2, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "last-nine-month":
        serchObj.startDate = moment()
          .subtract(8, "months")
          .startOf("month")
          .format("MM/DD/YYYY");
        serchObj.endDate = moment().endOf("month").format("MM/DD/YYYY");
        break;
      case "custom-date":
        serchObj.startDate = moment(serchObj.startDate).format("MM/DD/YYYY");
        serchObj.endDate = moment(serchObj.endDate).format("MM/DD/YYYY");
        break;
      default:
        delete serchObj.startDate;
        delete serchObj.endDate;
        break;
    }
    const result = await getAllOrders(serchObj);
    // replace url to original url
    if (queryParams.get("status") || queryParams.get("timePeriod")) {
      window.history.replaceState(null, "", "/orders");
    }
    if (!serchObj.status) {
      serchObj.status = 99;
    }
    if (!serchObj.deliveryVerificationStatus) {
      serchObj.deliveryVerificationStatus = 99;
    }
    if (!serchObj.paymentMethod) {
      serchObj.paymentMethod = "all";
    }
    if (result && result.success) {
      setOrders(result.data);
      setSearchObj(serchObj);
      setHasMoreOrders(result.data.length === searchObj.limit);
    } else {
      showToast(result?.message || "Error while getting orders list", "error");
    }
    setLoading(false);
  };

  const getDeliveryBoy = async () => {
    // setLoading(true);
    const result = await getUsers({ roleId: 2, isExport: true });
    if (result?.success) {
      const deliveryBoyDict = result.data.reduce((acc: any, d) => {
        const name = getFullName(d);
        acc[d._id as string] = name;
        return acc;
      }, {});
      setDeliveryBoyDict(deliveryBoyDict);
    } else {
      showToast(
        result?.message || "Error while getting delivery boy list",
        "error"
      );
    }
    // setLoading(false);
  };

  const getOrdersFrom = async (offset: number) => {
    let search = { ...searchObj };
    search.offset = offset;
    await getOrdersList(search);
  };

  const handleChange = (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    if (name === "timePeriod" && value === "custom-date") {
      setSearchObj({
        ...searchObj,
        timePeriod: value,
        startDate: undefined,
        endDate: undefined,
      });
    } else {
      setSearchObj({
        ...searchObj,
        [name]: value,
      });
    }
  };

  const onSearch = async () => {
    let serchObj = { ...searchObj };
    serchObj.offset = 0;
    await getOrdersList(serchObj);
  };

  const handleDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = orders[editIndex];
    }
    setManageDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleDialogClose = (data?: IOrder) => {
    let orderList = [...orders];
    if (data) {
      if (manageDialog.editIndex !== -1) {
        orderList[manageDialog.editIndex] = data;
      }
    }
    setOrders(orderList);
    setManageDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const handleApproveDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = orders[editIndex];
    }
    setManageApproveDialog({
      ...manageApproveDialog,
      isOpen: isOpen,
      data: isOpen ? data : {},
      editIndex: isOpen ? (editIndex as number) : -1,
    });
  };

  const handleApproveDialogClose = (data?: IOrder) => {
    let orderList = [...orders];
    if (data) {
      if (manageApproveDialog.editIndex !== -1) {
        orderList[manageApproveDialog.editIndex] = data;
      }
    }
    setOrders(orderList);
    setManageApproveDialog({
      // ...manageApproveDialog,
      isOpen: false,
      data: undefined,
      editIndex: -1,
      status: 5,
      remarks: "",
    });
  };

  const handleStatusChange = (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    setManageApproveDialog({
      ...manageApproveDialog,
      [name]: value,
    });
  };

  const onApproveOrRejectOrder = async () => {
    let data = { ...manageApproveDialog.data };
    data.status = Number(manageApproveDialog.status);
    data.remarks = manageApproveDialog.remarks;
    if (manageApproveDialog.status == 5) {
      setLoading(true);
      let result = await updateAOrderDetails(data);
      setLoading(false);
      if (result?.success) {
        handleApproveDialogClose(data);
        showToast("Order status updated successfully", "success");
      } else {
        showToast(
          result?.message || "Error while updating order status",
          "error"
        );
      }
    } else {
      showConfirmDialog(
        "Are you sure",
        "Do you want to reject this order?",
        async () => {
          setLoading(true);
          let result = await rejectAOrder(data);
          setLoading(false);
          if (result?.success) {
            showToast("Order status updated successfully", "success");
            handleApproveDialogClose(data);
          } else {
            showToast(
              result?.message || "Error while updating order status",
              "error"
            );
          }
        }
      );
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Orders</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className="padding-16">
            <Grid
              container
              spacing={2}
              alignItems="center"
            // justify="space-between"
            >
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="userName"
                  label="Search by customer name"
                  value={searchObj.userName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="search"
                  label="Search by order Id"
                  value={searchObj.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="deliveryBoy" className="select-label">
                    Delivery Boy
                  </InputLabel>
                  <Select
                    labelId="deliveryBoy"
                    value={searchObj.deliveredBy}
                    name="deliveredBy"
                    onChange={handleChange}
                  >
                    {Object.keys(deliveryBoyDict).map((index) => (
                      <MenuItem value={index} key={index}>
                        {deliveryBoyDict[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status" className="select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="status"
                    value={searchObj.status}
                    name="status"
                    onChange={handleChange}
                  >
                    {Object.keys(ORDER_STATUS_WITH_ALL).map((index) => (
                      <MenuItem value={index} key={index}>
                        {ORDER_STATUS_WITH_ALL[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={searchObj.timePeriod === "custom-date" ? 3 : 4}
                md={searchObj.timePeriod === "custom-date" ? 3 : 4}
                sm={6}
                xs={12}
              >
                <FormControl fullWidth>
                  <InputLabel id="paymentType" className="select-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    labelId="paymentType"
                    value={searchObj.paymentMethod}
                    name="paymentMethod"
                    onChange={handleChange}
                  >
                    {Object.keys(PAYMENT_METHOD_WITH_ALL).map((index) => (
                      <MenuItem value={index} key={index}>
                        {PAYMENT_METHOD_WITH_ALL[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                lg={searchObj.timePeriod === "custom-date" ? 3 : 4}
                md={searchObj.timePeriod === "custom-date" ? 3 : 4}
                sm={6}
                xs={12}
              >
                <FormControl fullWidth>
                  <InputLabel id="timePeriod" className="select-label">
                    Time Period
                  </InputLabel>
                  <Select
                    labelId="timePeriod"
                    value={searchObj.timePeriod}
                    name="timePeriod"
                    onChange={handleChange}
                  >
                    {Object.keys(TIME_PERIOD).map((index) => (
                      <MenuItem value={index} key={index}>
                        {TIME_PERIOD[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {searchObj.timePeriod === "custom-date" && (
                <>
                  <Grid item lg={2} md={3} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="startDate"
                      type="date"
                      label="Start Date"
                      value={moment(searchObj.startDate).format("YYYY-MM-DD")}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={3} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      name="endDate"
                      type="date"
                      label="End Date"
                      value={moment(searchObj.endDate).format("YYYY-MM-DD")}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="deliveryStatus" className="select-label">
                    Delivery Status
                  </InputLabel>
                  <Select
                    labelId="deliveryStatus"
                    value={searchObj.deliveryVerificationStatus}
                    name="deliveryVerificationStatus"
                    onChange={handleChange}
                  >
                    {Object.keys(DELIVERY_STATUS_WITH_ALL).map((index) => (
                      <MenuItem value={index} key={index}>
                        {DELIVERY_STATUS_WITH_ALL[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item>
                <Button variant="contained" color="primary" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    const searchObj = {
                      offset: 0,
                      limit: 10,
                      search: "",
                      userName: "",
                      status: 99,
                      paymentMethod: "all",
                      deliveryVerificationStatus: 99,
                      deliveredBy: "",
                      timePeriod: "all",
                      startDate: undefined,
                      endDate: undefined,
                    };
                    await getOrdersList(searchObj);
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>No Of Items</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Delivery Charge</TableCell>
                  <TableCell>Total Price</TableCell>
                  <TableCell>Order Status</TableCell>
                  {/* <TableCell>Delivery Status</TableCell> */}
                  <TableCell>Delivery Boy</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.length > 0 ? (
                  orders.map((order, index) => (
                    <TableRow key={order._id}>
                      <TableCell>
                        <Typography>{order.orderNo}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {moment(order.createdAt).format("DD/MM/YY hh:mm a")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{order.userName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{order?.paymentDetail?.invoiceNo || "NA"}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {PAYMENT_METHOD[order.paymentMethod] || "NA"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{order.totalItem}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{order.totalEffectivePrice}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{order.effectiveDeliveryCharge}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {order.totalEffectivePrice +
                            order.effectiveDeliveryCharge}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{ORDER_STATUS[order.status]}</Typography>
                      </TableCell>
                      {/* <TableCell>
                        <Typography>
                          {DELIVERY_STATUS[order.deliveryVerificationStatus]}
                        </Typography>
                      </TableCell> */}
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            <Typography>
                              {deliveryBoyDict[order.deliveredBy]}
                            </Typography>
                          </Grid>
                          {order.deliveredBy && (
                            <Grid item>
                              {order.deliveryBoyApprovalStatus === 1 ? (
                                <Tooltip title="Accepted for delivery">
                                  <IconButton
                                    size="small"
                                    style={{ color: "green" }}
                                  >
                                    <CheckDecagram />
                                  </IconButton>
                                </Tooltip>
                              ) : order.deliveryBoyApprovalStatus === -1 ? (
                                <Tooltip title="Rejected for delivery">
                                  <IconButton
                                    size="small"
                                    style={{ color: "red" }}
                                  >
                                    <CloseOctagon />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Pending for Approval">
                                  <IconButton size="small" color="default">
                                    <AlertDecagram />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            <Tooltip title="View Order Details">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() =>
                                  setManageOrderDetailsDialog({
                                    isOpen: true,
                                    id: order._id as string,
                                  })
                                }
                              >
                                <Eye />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          {(order.status === 1 || order.status === -5) && (
                            <Grid item>
                              <Tooltip title="Approve/Reject Order">
                                <IconButton
                                  size="medium"
                                  color="primary"
                                  onClick={() =>
                                    handleApproveDialogOpen(true, index)
                                  }
                                >
                                  <Pencil />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {[5, 7].includes(order.status) && (
                            <Grid item>
                              <Tooltip title="Assign Delivery Boy">
                                <IconButton
                                  size="medium"
                                  color="primary"
                                  onClick={() => handleDialogOpen(true, index)}
                                >
                                  <AccountPlus />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <NoData msg="No Orders found" isTable={true} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2} justify="flex-end" className="padding-16">
            {(searchObj?.offset || 0) > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getOrdersFrom(
                      (searchObj?.offset || 0) - (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {hasMoreOrders && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getOrdersFrom(
                      (searchObj?.offset || 0) + (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* order approval or rejection */}
      {manageApproveDialog.isOpen && (
        <CustomDialog
          title={"Approve/Reject Order"}
          handleOpen={true}
          // fullWidth={true}
          onClose={() => handleApproveDialogClose()}
        >
          <section className="p-16">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status" className="select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="status"
                    value={manageApproveDialog.status}
                    name="status"
                    onChange={handleStatusChange}
                  >
                    {Object.keys(ORDER_APPROVAL_STATUS).map((index) => {
                      if (manageApproveDialog.data.status !== Number(index)) {
                        return (
                          <MenuItem value={index} key={index}>
                            {ORDER_APPROVAL_STATUS[index]}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {manageApproveDialog.status == -5 && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="remarks"
                    label="Remarks"
                    value={manageApproveDialog.remarks}
                    onChange={handleStatusChange}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => handleApproveDialogClose()}
                      variant="outlined"
                      color="secondary"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onApproveOrRejectOrder}
                    >
                      {"Update"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>
        </CustomDialog>
      )}
      {/* order details */}
      {manageOrderDetailsDialog.isOpen && (
        <CustomerDrawer
          title={"Order Details"}
          handleOpen={true}
          // fullWidth={true}
          onClose={() =>
            setManageOrderDetailsDialog({ isOpen: false, id: undefined })
          }
        >
          <ManageOrderDialog orderId={manageOrderDetailsDialog.id as string} />
        </CustomerDrawer>
      )}
      {/* assign delivery boy */}
      {manageDialog.isOpen && (
        <CustomerDrawer
          title={"Assign Delivery Boy"}
          handleOpen={true}
          onClose={() => handleDialogClose()}
        >
          <AssignDeliveryBoyForm
            onClose={(data: IOrder) => handleDialogClose(data)}
            data={manageDialog.data as IOrder}
            deliveryBoyDict={deliveryBoyDict}
          />
          {/* <ManageOrderDialog orderId={manageOrderDetailsDialog.id as string} /> */}
        </CustomerDrawer>
      )}
    </React.Fragment>
  );
};

export default OrdersMain;
