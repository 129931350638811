import DefaultImg from "./images/kbr-default-image.png";
// localhost

export const API_URL = "https://vni8qjt0l2.execute-api.ap-south-1.amazonaws.com/dev/"
// dev
// export const API_URL =
//   "http://ec2-52-66-110-219.ap-south-1.compute.amazonaws.com:8080/";

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const USER_ROLES: any = {
  0: "Admin",
  1: "Customer",
  2: "Delivery Boy",
};

export const GENDERS: any = {
  male: "Male",
  female: "Female",
  other: "Others",
};

export const ADDRESS_TYPE: any = {
  home: "Home",
  work: "Work",
};

export const PRODUCT_UNIT: any = {
  weight: "Weight",
  piece: "Piece",
  pack: "Pack",
  combo: "Combo",
};

export const STATUS_DICT: any = {
  0: "Listed(not available for customer)",
  1: "Live(customer can search)",
  "-1": "Not Available Right Now",
};

export const STATUS_DICT_FOR_PRODUCT: any = {
  ...STATUS_DICT,
  "-2": "Out Of Stock",
};

export const STATUS_DICT_WITH_ALL: any = {
  9: "All",
  ...STATUS_DICT,
};

export const STATUS_DICT_FOR_PRODUCT_WITH_ALL: any = {
  9: "All",
  ...STATUS_DICT_FOR_PRODUCT,
};

export const DEFAULT_IMG_URL = DefaultImg;

export const ORDER_STATUS: any = {
  "0": "Order Initiated",
  "1": "Order Placed",
  "-1": "Order Failed",
  "2": "Order Delivered",
  "-2": "Refused by Customer",
  "-3": "Cancelled by Customer",
  "-4": "Cancelled by Admin",
  "5": "Order Approved",
  "-5": "Order Rejected",
  "6": "Order in progress",
  "7": "Picked up from seller",
};

export const ORDER_APPROVAL_STATUS: any = {
  "5": "Approved",
  "-5": "Rejected",
};

export const PAYMENT_METHOD: any = {
  cash: "Cash On Delivery",
  // card: "Card",
  // upi: "UPI",
  online: "Paid Online",
};

export const DELIVERY_STATUS: any = {
  0: "Initial State",
  1: "Verified",
  "-1": "Not Verified",
};

export const DASHBOARD_STATUS_LABEL: any = {
  0: "Listed",
  1: "Available",
  "-1": "Not Available",
  "-2": "Out Of Stock",
};

export const TIME_PERIOD: any = {
  all: "All",
  today: "Today",
  // yesterday: "Yesterday",
  "this-week": "This Week",
  "this-month": "This Month",
  "last-three-month": "Last 3 Month",
  // "last-six-month": "Last 6 Month",
  "last-nine-month": "Last 9 Month",
  "custom-date": "Custom Date",
};
