import { Grid } from "@material-ui/core";
import { AccountGroup, Apps, CartCheck, Shopping } from "mdi-material-ui";
import React from "react";
import { IDashboardData } from "../../vm";
import DashboardCard from "./DashboardCard";

export interface DashboardTotalCountsProps {
  data: IDashboardData;
}

const DashboardTotalCounts: React.FC<DashboardTotalCountsProps> = ({
  data,
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <DashboardCard
            title="Total Orders"
            count={data.totalOrder}
            icon={<CartCheck />}
            color="white"
            secondaryColor="#35baf6"
            url={`/orders`}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <DashboardCard
            title="Total Customers"
            count={data.totalCustomer}
            icon={<AccountGroup />}
            color="white"
            secondaryColor="#009688"
            url={`/users?roleId=${1}`}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <DashboardCard
            title="Total Categories"
            count={data.totalCategory}
            icon={<Apps />}
            color="white"
            secondaryColor="#ffcd38
            "
            url={`/categories`}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <DashboardCard
            title="Total Products"
            count={data.totalProduct}
            icon={<Shopping />}
            color="white"
            secondaryColor="#ff5722"
            url={`/products`}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DashboardTotalCounts;
