import React from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ICategory, ICategorySearchObj } from "../../vm";
import { ConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import {
  Pencil,
  TrashCan,
  OpenInNew,
  ChevronLeft,
  ChevronRight,
  TableEye,
} from "mdi-material-ui";
import { deleteACategory, getCategories } from "../../services/CategoryService";
import CategoryForm from "./CategoryForm";
import NoData from "../common/NoData";
import SubCategoryMain from "./sub-category/SubCategoryMain";
import CustomerDrawer from "../common/CustomerDrawer";
import CategoryCard from "./CategoryCard";
import {
  STATUS_DICT_WITH_ALL,
  DEFAULT_IMG_URL,
  STATUS_DICT,
} from "../../Constant";
import { CustomSelect } from "../common/Library";

export interface CategoryMainProps { }

const CategoryMain: React.FC<CategoryMainProps> = () => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);
  const queryParams = new URLSearchParams(window.location.search);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [category, setCategory] = React.useState([] as ICategory[]);
  const [hasMoreCategory, setHasMoreCategory] = React.useState(
    false as boolean
  );
  const [manageCategoryDialog, setManageCategoryDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });
  const [manageSubCategoryDialog, setManageSubCategoryDialog] = React.useState({
    isOpen: false,
    data: {},
    editIndex: -1,
  } as {
    isOpen: boolean;
    data: any;
    editIndex: number;
  });
  const [searchObj, setSearchObj] = React.useState({
    offset: 0,
    limit: 10,
    search: "",
    status: queryParams.get("status") ? Number(queryParams.get("status")) : 9,
    showAllData: true,
  } as ICategorySearchObj);

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getAllCategory();
    };
    asyncFunc();
  }, []);

  const getAllCategory = async (search?: any) => {
    setLoading(true);
    let serchObj = { ...searchObj };
    if (search != null) {
      serchObj = { ...serchObj, ...search };
    }
    // const status = serchObj.status;
    if (Number(serchObj.status) === 9) {
      delete serchObj.status;
      serchObj.showAllData = true;
    } else {
      serchObj.status = Number(serchObj.status);
      serchObj.showAllData = false;
    }
    const result = await getCategories(serchObj);
    // replace url to original url
    if (queryParams.get("status")) {
      window.history.replaceState(null, "", "/categories");
    }
    if (serchObj.status == null) {
      serchObj.status = 9;
    }
    if (result && result.success) {
      setCategory(result.data);
      setSearchObj(serchObj);
      setHasMoreCategory(result.data.length === serchObj.limit);
    } else {
      showToast(result?.message || "Error while getting categories", "error");
    }
    setLoading(false);
  };

  const getCategoriesFrom = async (offset: number) => {
    let search = { ...searchObj };
    search.offset = offset;
    await getAllCategory(search);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = category[editIndex];
    }
    setManageCategoryDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleClose = (data?: ICategory) => {
    let categoryList = [...category];
    if (data) {
      if (manageCategoryDialog.editIndex !== -1) {
        categoryList[manageCategoryDialog.editIndex] = data;
      } else {
        categoryList.unshift(data);
      }
    }
    setCategory(categoryList);
    setManageCategoryDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const deleteCategory = (index: number) => {
    showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
      let categoryList = [...category];
      setLoading(true);
      let result = await deleteACategory(categoryList[index]._id as string);
      setLoading(false);
      if (result?.success) {
        showToast("User category deleted successfully", "success");
        categoryList.splice(index, 1);
        setCategory(categoryList);
      } else {
        showToast(result?.message || "Error while deleting category", "error");
      }
    });
  };

  const handleSubCategoryDialogOpenClose = (
    isOpen: boolean,
    editIndex?: number
  ) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = category[editIndex];
    }
    setManageSubCategoryDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleChange = (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    setSearchObj({
      ...searchObj,
      [name]: value,
    });
  };

  const onSearch = async () => {
    let serchObj = { ...searchObj };
    serchObj.offset = 0;
    await getAllCategory(serchObj);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Categories</Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddDialogOpen(true)}
              >
                Add Category
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className="padding-16">
            <Grid container spacing={2} alignItems="center">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="search"
                  label="Search by name / slug"
                  value={searchObj.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item lg={3} md={2} sm={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status" className="select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="status"
                    value={searchObj.status}
                    name="status"
                    onChange={handleChange}
                  >
                    {Object.keys(STATUS_DICT_WITH_ALL).map((index) => (
                      <MenuItem value={index} key={index}>
                        {STATUS_DICT_WITH_ALL[index]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    const searchObj = {
                      offset: 0,
                      limit: 10,
                      search: "",
                      status: 9,
                      showAllData: true,
                    };
                    await getAllCategory(searchObj);
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Slug</TableCell>
                  {/*<TableCell>Delivery Time</TableCell>*/}
                  <TableCell>Enabled in HomeFeed</TableCell>
                  {/*<TableCell>Same category order only</TableCell>*/}
                  {/* <TableCell>Is Enabled</TableCell> */}
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {category.length > 0 ? (
                  category.map((d, index) => (
                    <TableRow key={d._id}>
                      <TableCell>
                        {d.imageUrl ? (
                          <img
                            src={d.imageUrl}
                            alt="log"
                            className="sub-category-img"
                          />
                        ) : (
                          <img
                            src={DEFAULT_IMG_URL}
                            alt="log"
                            className="sub-category-img"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography>{d.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{d.slug}</Typography>
                      </TableCell>
                      {/*<TableCell>*/}
                      {/*    <Typography>*/}
                      {/*        {d.deliveryTime || "NA"}*/}
                      {/*    </Typography>*/}
                      {/*</TableCell>*/}
                      <TableCell>
                        <Typography>
                          {d.isShowInAppDashBoard === true ? "Yes" : "No"}
                        </Typography>
                      </TableCell>
                      {/*<TableCell>*/}
                      {/*    <Typography>*/}
                      {/*        {d.dontAllowToOrderWithOtherCat === true ? "Yes" : "No"}*/}
                      {/*    </Typography>*/}
                      {/*</TableCell>*/}
                      <TableCell>
                        <Typography>{STATUS_DICT[d.status]}</Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            <Tooltip title="Update Category">
                              <IconButton
                                size="medium"
                                color="primary"
                                onClick={() => handleAddDialogOpen(true, index)}
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Delete Category">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() => deleteCategory(index)}
                              >
                                <TrashCan />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="View Sub-Category">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() =>
                                  handleSubCategoryDialogOpenClose(true, index)
                                }
                              >
                                <TableEye />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <NoData msg="No Category found" isTable={true} />
                      {/* <br />
                      <Typography variant="subtitle1">
                        No Category found
                      </Typography>
                      <br /> */}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2} justify="flex-end" className="padding-16">
            {(searchObj?.offset || 0) > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getCategoriesFrom(
                      (searchObj?.offset || 0) - (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {hasMoreCategory && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getCategoriesFrom(
                      (searchObj?.offset || 0) + (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {manageCategoryDialog.isOpen && (
        <CustomerDrawer
          title={
            manageCategoryDialog?.data?._id ? "Edit Category" : "Add Category"
          }
          handleOpen={true}
          onClose={() => handleClose()}
        >
          <CategoryForm
            onClose={handleClose}
            data={manageCategoryDialog.data}
          />
        </CustomerDrawer>
      )}
      {manageSubCategoryDialog.isOpen && (
        <SubCategoryMain
          onClose={() => handleSubCategoryDialogOpenClose(false)}
          categoryId={manageSubCategoryDialog.data._id as string}
        />
      )}
    </React.Fragment>
  );
};

export default CategoryMain;
