/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, List, CardContent, Typography } from "@material-ui/core";
import {
  Home,
  Translate,
  AccountGroup,
  FilmstripBoxMultiple,
  MovieOpen,
  CartCheck,
  Apps,
  Image,
  MapMarkerMultiple,
  BrightnessPercent,
  Shopping,
  Card,
  Storefront
} from "mdi-material-ui";


import CustomMenuItem from "./CustomMenuItem";
import { getName } from "../../../services/UtilServices";
// import { parseJwt } from '../../../services/UtilService';

interface SideMenuProps {
  isMenuOpened?: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ isMenuOpened }) => {
  //   const userData = parseJwt();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [today, setDate] = useState(new Date());

  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    }, `;
  const userGreetings = () => {
    return (
      <div>
        <h4>{wish} {getName()}</h4>
      </div>
    );
  };


  React.useLayoutEffect(() => {
    const ROUTES: string[] = [
      "/dashboard",
      "/shops",
      "/orders",
      "/users",
      "/categories",
      "/products",
      "/banners",
      "/locations-group",
      "/home-feeds",
    ];
    let index = -1;
    ROUTES.forEach((d, i) => {
      if (window.location.pathname.includes(d)) {
        index = i;
      }
      if (index !== -1) {
        setSelectedIndex(index);
      }
    });
  }, [window.location.pathname]);

  return (
    <div >

      <div style={{
        paddingTop: "3rem", textAlign: "center"
      }}>
        <img src="/static/images/icons/logo512.png" height="90" width="90" alt="logo" />

        <Typography >
          {userGreetings()}
        </Typography>

      </div>

      <div style={{ marginTop: "3rem" }}>

        <List
          className={`side-menu-list custom-side-menu ${isMenuOpened ? "menu-opened" : "menu-closed"
            }`}
        >
          <CustomMenuItem
            index={0}
            selectedIndex={selectedIndex}
            to="/dashboard"
            onClick={setSelectedIndex}
            titleText="Dashboard"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 0 ? "selected-menu" : ""
              }`}
            icon={<Home color="primary" fontSize="large" />}
          />
          <CustomMenuItem
            index={1}
            selectedIndex={selectedIndex}
            to="/shops"
            onClick={setSelectedIndex}
            titleText="Shops"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 1 ? "selected-menu" : ""
              }`}
            icon={<Storefront color="primary" fontSize="large" />}
          />
          <CustomMenuItem
            index={2}
            selectedIndex={selectedIndex}
            to="/orders"
            onClick={setSelectedIndex}
            titleText="Orders"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 2 ? "selected-menu" : ""
              }`}
            icon={<CartCheck color="primary" fontSize="large" />}
          />

          <CustomMenuItem
            index={3}
            selectedIndex={selectedIndex}
            to="/categories"
            onClick={setSelectedIndex}
            titleText="Categories"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 3 ? "selected-menu" : ""
              }`}
            icon={<Apps color="primary" fontSize="large" />}
          />
          <CustomMenuItem
            index={4}
            selectedIndex={selectedIndex}
            to="/products"
            onClick={setSelectedIndex}
            titleText="Products"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 4 ? "selected-menu" : ""
              }`}
            icon={<Shopping color="primary" fontSize="large" />}
          />
          <CustomMenuItem
            index={5}
            selectedIndex={selectedIndex}
            to="/banners"
            onClick={setSelectedIndex}
            titleText="Banners"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 5 ? "selected-menu" : ""
              }`}
            icon={<Image color="primary" fontSize="large" />}
          />
          <CustomMenuItem
            index={6}
            selectedIndex={selectedIndex}
            to="/locations-group"
            onClick={setSelectedIndex}
            titleText="Locations Group"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 6 ? "selected-menu" : ""
              }`}
            icon={<MapMarkerMultiple color="primary" fontSize="large" />}
          />
          <CustomMenuItem
            index={7}
            selectedIndex={selectedIndex}
            to="/home-feeds"
            onClick={setSelectedIndex}
            titleText="Home Feeds"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 7 ? "selected-menu" : ""
              }`}
            icon={<BrightnessPercent color="primary" fontSize="large" />}
          />
          <CustomMenuItem
            index={8}
            selectedIndex={selectedIndex}
            to="/users"
            onClick={setSelectedIndex}
            titleText="Users"
            class={`menu-link fs-16 fw-medium ${selectedIndex === 8 ? "selected-menu" : ""
              }`}
            icon={<AccountGroup color="primary" fontSize="large" />}
          />
        </List>
      </div>
    </div>
  );
};

export default SideMenu;
