import { httpClient } from "./UtilServices";
import { IStandardAPIResponse, IHomeFeed, IHomeFeedSearchObj } from "../vm";

export const addAHomeFeed = async (
  data: IHomeFeed
): Promise<IStandardAPIResponse<IHomeFeed>> => {
  try {
    let res = await httpClient<IHomeFeed>(`home-feeds`, "PUT", data);
    return res;
  } catch (err) {
    return err;
  }
};

export const updateAHomeFeed = async (
  data: IHomeFeed
): Promise<IStandardAPIResponse<IHomeFeed>> => {
  try {
    let res = await httpClient<IHomeFeed>(
      `home-feeds/${data._id}`,
      "PATCH",
      data
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const deleteAHomeFeed = async (
  id: string
): Promise<IStandardAPIResponse<any>> => {
  try {
    let res = await httpClient(`home-feeds/${id}`, "DELETE");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};

export const getHomeFeeds = async (
  searchObj: IHomeFeedSearchObj
): Promise<IStandardAPIResponse<Array<IHomeFeed>>> => {
  try {
    let res = await httpClient<Array<IHomeFeed>>(
      `home-feeds`,
      "GET",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
};
