import React from "react";
import { ILocationGroup } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import { Formik, FieldArray } from "formik";
import {
  updateALocationGroup,
  addALocationGroup,
} from "../../services/LocationGroupService";
import {
  Grid,
  Button,
  Typography,

  IconButton,
  Tooltip,
} from "@material-ui/core";
import { debounce, parseJwt, removeNulls } from "../../services/UtilServices";
import { Loading } from "../common/Loading";
import { CustomSelect, CustomTextField } from "../common/Library";
import * as yup from "yup";
import { Minus, Plus } from "mdi-material-ui";
import { STATUS_DICT } from "../../Constant";

const locationsSchema = yup.object().shape({
  title: yup.string().required("required"),
  pincode: yup.string().length(6).required("required"),
});

const locationGroupSchema = yup.object().shape({
  title: yup.string().required("required"),
  locations: yup.array(locationsSchema),
});

export interface LocationGroupFormProps {
  onClose: Function;
  data?: ILocationGroup;
}

const LocationGroupForm: React.FC<LocationGroupFormProps> = ({
  onClose,
  data,
}) => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);

  const [isLoading, setLoading] = React.useState(false as boolean);
  const [locationGroup, setLocationGroup] = React.useState({
    title: "",
    locations: [{ title: "", pincode: "" }],
    isDeleted: false,
    status: 0,
    ...data,
  } as ILocationGroup);
  const [selectedImage, setSelectedImage] = React.useState(undefined as any);

  const onDialogClose = (data?: ILocationGroup) => {
    onClose(data);
  };

  const onAddRow = (values: ILocationGroup) => {
    values.locations.push({ title: " ", pincode: " " });
    setLocationGroup(values);
  };

  const onRemoveRow = (values: ILocationGroup) => {
    values.locations.splice(values.locations.length - 1);
    setLocationGroup(values);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="padding-16">
        <Formik
          enableReinitialize
          initialValues={locationGroup}
          validationSchema={locationGroupSchema}
          onSubmit={async (values: ILocationGroup, { setSubmitting }) => {
            setLoading(true);
            let res;
            let obj = { ...values };
            obj.locations = obj.locations.map((d) => {
              d.pincode = String(d.pincode);
              return d;
            });
            obj = removeNulls(obj);
            if (values._id) {
              res = await updateALocationGroup(obj);
            } else {
              res = await addALocationGroup(obj);
            }
            setLoading(false);
            if (res?.success) {
              showToast(
                `Location group ${values._id ? "updated" : "created"
                } successfully`,
                "success"
              );
              onDialogClose(values._id ? obj : res.data);
            } else {
              showToast(
                res?.message ||
                `Error while ${values._id ? "updating" : "creating"
                } location-group`,
                "error"
              );
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit /* and other goodies */,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomTextField name="title" label="Location Group Name" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <CustomSelect
                    name="status"
                    label="Status"
                    menuList={Object.keys(STATUS_DICT).map((d) => {
                      return { value: d, label: STATUS_DICT[d] };
                    })}
                  />
                </Grid>
                <FieldArray name="locations">
                  {({ insert, remove }) => (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="subtitle2">
                              Location Details
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container justify="flex-end" spacing={2}>
                              {/* {values.locations.length > 1 && (
                                <Grid item>
                                  <Tooltip title="Remove First Row">
                                    <IconButton
                                      size="medium"
                                      //   color="secondary"
                                      onClick={() =>
                                        remove(0)
                                      }
                                    >
                                      <Minus />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              )} */}

                              <Grid item>
                                <Tooltip title="Add Row">
                                  <IconButton
                                    size="medium"
                                    // color="primary"
                                    onClick={() =>
                                      insert(0, { title: "", pincode: "" })
                                    }
                                  >
                                    <Plus />
                                  </IconButton>
                                </Tooltip>
                                {/* <Button
                                  onClick={() =>
                                    insert(0, { title: "", pincode: "" })
                                  }
                                  variant="outlined"
                                  color="default"
                                  size="small"
                                  startIcon={<Plus />}
                                >
                                  Add Row
                                </Button> */}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {values.locations.map((d, index) => (
                        <Grid container alignItems="center" spacing={2}>
                          <Grid
                            item
                            lg={values.locations.length > 1 ? 5 : 6}
                            md={values.locations.length > 1 ? 5 : 6}
                            sm={12}
                            xs={12}
                          >
                            <CustomTextField
                              name={`locations[${index}].title`}
                              label="Location Name"
                            />
                          </Grid>
                          <Grid
                            item
                            lg={values.locations.length > 1 ? 5 : 6}
                            md={values.locations.length > 1 ? 5 : 6}
                            sm={12}
                            xs={12}
                          >
                            <CustomTextField
                              name={`locations[${index}].pincode`}
                              label="Pincode"
                              type="number"
                            />
                          </Grid>
                          {values.locations.length > 1 && (
                            <Grid item lg={2} md={2} sm={12} xs={12}>
                              <Button
                                onClick={() => remove(index)}
                                variant="outlined"
                                color="default"
                                size="small"
                              // startIcon={<Minus />}
                              >
                                Remove
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </>
                  )}
                </FieldArray>

                <Grid item xs={12}>
                  <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => onClose()}
                        variant="outlined"
                        color="secondary"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        {values._id ? "Update" : "Add"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </section>
    </React.Fragment>
  );
};

export default LocationGroupForm;
