import React from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TableContainer,
  Chip,
  TextField,
  Fab,
} from "@material-ui/core";
import { ILocationGroup, ILocationGroupSearchObj } from "../../vm";
import { ConfirmDialogContext } from "../common/ConfirmDialogProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import { ChevronLeft, ChevronRight, Pencil, TrashCan } from "mdi-material-ui";
import {
  getLocationGroups,
  deleteALocationGroup,
} from "../../services/LocationGroupService";
import NoData from "../common/NoData";
import CustomerDrawer from "../common/CustomerDrawer";
import LocationGroupForm from "./LocationGroupForm";
import { STATUS_DICT } from "../../Constant";

export interface LocationGroupMainProps { }

const LocationGroupMain: React.FC<LocationGroupMainProps> = () => {
  // custom hooks
  const { showToast } = React.useContext(ToastContext);
  const { showConfirmDialog } = React.useContext(ConfirmDialogContext);

  // state
  const [isLoading, setLoading] = React.useState(false as boolean);
  const [locationGroups, setLocationGroups] = React.useState(
    [] as ILocationGroup[]
  );
  const [manageLocationGroupDialog, setManageLocationGroupDialog] =
    React.useState({
      isOpen: false,
      data: {},
      editIndex: -1,
    } as {
      isOpen: boolean;
      data: any;
      editIndex: number;
    });
  const [searchObj, setSearchObj] = React.useState({
    offset: 0,
    limit: 10,
    search: "",
    showAllData: true,
  } as ILocationGroupSearchObj);
  const [hasMoreLocationGroup, setHasMoreLocationGroup] = React.useState(
    false as boolean
  );

  React.useEffect(() => {
    const asyncFunc = async () => {
      await getAllLocationGroups();
    };
    asyncFunc();
  }, []);

  const getAllLocationGroups = async (search?: any) => {
    setLoading(true);
    let serchObj = { ...searchObj };
    if (search != null) {
      serchObj = { ...serchObj, ...search };
    }
    const status = serchObj.status;
    if (serchObj.status == 9) {
      delete serchObj.status;
    }
    const result = await getLocationGroups(serchObj);
    if (status == 9) {
      serchObj.status = 9;
    }
    if (result && result.success) {
      setLocationGroups(result.data);
      setSearchObj(serchObj);
      setHasMoreLocationGroup(result.data.length === serchObj.limit);
    } else {
      showToast(
        result?.message || "Error while getting location-groups",
        "error"
      );
    }
    setLoading(false);
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = locationGroups[editIndex];
    }
    setManageLocationGroupDialog({
      isOpen: isOpen,
      data: data,
      editIndex: editIndex !== undefined ? editIndex : -1,
    });
  };

  const handleClose = (data?: ILocationGroup) => {
    let locationGroupList = [...locationGroups];
    if (data) {
      if (manageLocationGroupDialog.editIndex !== -1) {
        locationGroupList[manageLocationGroupDialog.editIndex] = data;
      } else {
        locationGroupList.unshift(data);
      }
    }
    setLocationGroups(locationGroupList);
    setManageLocationGroupDialog({
      isOpen: false,
      data: undefined,
      editIndex: -1,
    });
  };

  const deleteLocationGroup = (index: number) => {
    showConfirmDialog("Are you sure", "Do you want to delete?", async () => {
      let locationGroupList = [...locationGroups];
      setLoading(true);
      let result = await deleteALocationGroup(
        locationGroupList[index]._id as string
      );
      setLoading(false);
      if (result?.success) {
        showToast("location-group deleted successfully", "success");
        locationGroupList.splice(index, 1);
        setLocationGroups(locationGroupList);
      } else {
        showToast(
          result?.message || "Error while deleting location-group",
          "error"
        );
      }
    });
  };

  const getLocationGroupsFrom = async (offset: number) => {
    let search = { ...searchObj };
    search.offset = offset;
    await getAllLocationGroups(search);
  };

  const handleChange = (event: any) => {
    let name = event.target.name,
      value = event.target.value;
    setSearchObj({
      ...searchObj,
      [name]: value,
    });
  };

  const onSearch = async () => {
    let serchObj = { ...searchObj };
    serchObj.offset = 0;
    await getAllLocationGroups(serchObj);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">Locations Group</Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddDialogOpen(true)}
              >
                Add Location Group
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper className="padding-16">
            <Grid
              container
              spacing={2}
              alignItems="center"
            // justify="space-around"
            >
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <TextField
                  fullWidth
                  name="search"
                  label="Search by location-group name/location name/pincode"
                  value={searchObj.search}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={onSearch}>
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    const searchObj = {
                      offset: 0,
                      limit: 10,
                      search: "",
                      showAllData: true,
                    };
                    await getAllLocationGroups(searchObj);
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Locations Group Name</TableCell>
                  <TableCell>Locations</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationGroups.length > 0 ? (
                  locationGroups.map((data, index) => (
                    <TableRow key={data._id}>
                      <TableCell>
                        <Typography>{data.title}</Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container xs={12} spacing={1}>
                          {data.locations.map((d) => (
                            <Grid item>
                              <Chip
                                // color="primary"
                                label={d.title + ` (${d.pincode})`}
                              />
                            </Grid>
                          ))}
                        </Grid>
                        {/* <Typography></Typography> */}
                      </TableCell>
                      <TableCell>
                        <Typography>{STATUS_DICT[data.status]}</Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item>
                            <Tooltip title="Update Location Group">
                              <IconButton
                                size="medium"
                                color="primary"
                                onClick={() => handleAddDialogOpen(true, index)}
                              >
                                <Pencil />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Delete Location Group">
                              <IconButton
                                size="medium"
                                color="secondary"
                                onClick={() => deleteLocationGroup(index)}
                              >
                                <TrashCan />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      <NoData msg="No Location Group found" isTable={true} />
                      {/* <br />
                      <Typography variant="subtitle1">
                        No Location Group Found
                      </Typography>
                      <br /> */}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2} justify="flex-end" className="padding-16">
            {(searchObj?.offset || 0) > 0 && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getLocationGroupsFrom(
                      (searchObj?.offset || 0) - (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronLeft />
                </Fab>
              </Grid>
            )}
            {hasMoreLocationGroup && (
              <Grid item>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() =>
                    getLocationGroupsFrom(
                      (searchObj?.offset || 0) + (searchObj?.limit || 0)
                    )
                  }
                >
                  <ChevronRight />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {manageLocationGroupDialog.isOpen && (
        <CustomerDrawer
          title={
            manageLocationGroupDialog?.data?._id
              ? "Edit Location Group"
              : "Add Location Group"
          }
          handleOpen={true}
          onClose={() => handleClose()}
        >
          <LocationGroupForm
            onClose={handleClose}
            data={manageLocationGroupDialog.data}
          />
        </CustomerDrawer>
      )}
    </React.Fragment>
  );
};

export default LocationGroupMain;
